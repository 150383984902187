import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../utils/AuthContext.js";
import {
  Box,
  FormControlLabel,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { Link, Navigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { colors, orange } from "@mui/material/colors"; 
function Login() {
  const { loginUser, setLoginUser } = useContext(AuthContext);

  const RegisterButton = styled(Link)`
    text-align: center;
    text-decoration: none;
    color: ${orange[600]}; /* Correctly use colors here */
  `;

  const [personalCode, setpersonalCode] = useState(null); 
  const [password, setPassword] = useState(null);
  const [otp, setOTP] = useState(); 
  const [successLogin, setSuccessLogin] = useState(false); 
  const [checked, setChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const handleLogin = async () => {
    if (!personalCode || !password) {
      setSnack({
        open: true,
        message: "لطفا کد پرسنلی و گذرواژه را وارد کنید",
        severity: "error",
      });
      return;
    }
    fetch("https://api.asiamehr.com/api/v1/user", {
      method: "POST",
      headers: {
        "Content-type": "Application/json",
      },
      body: JSON.stringify({
        personalCode,
        password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
      
          setSuccessLogin(true);
          setLoginUser({ ...data?.data?.user });  ///new 
          setLoginUser({ phone:data?.data?.user.phone ,role: data?.data?.user.role,


            fullName: data?.data?.user?.fullName || 'Anonymous',
            email: data?.data?.user?.email || '',
            address: data?.data?.user?.address || 'No address provided',
            profilepic: data?.data?.user?.profilePic || '',
            personalCode: data?.data?.user?.personalCode || '',
            id:data?.data?.user._id,
            profilePic:data?.data?.user?.profilePic, 
            

           }); 

          setSnack({
            open: true,
            message: data.message,
            severity: "success",
          });
          setSuccessLogin(true);  
        } else {
          setSnack({
            open: true,
            message: data.message,
            severity: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setSnack({
          open: true,
          message: "خطایی رخ داده است",
          severity: "error",
        });
      });
  }; 
 
  const handleOTP = async () => {
    if (!otp) {
      setSnack({
        open: true,
        message: "لطفا کد تایید را وارد کنید",
        severity: "error",
      });
      return;
    }
    fetch("https://api.asiamehr.com/api/v1/user/verify", {
      method: "POST",
      headers: { "Content-type": "Application/json" },
      body: JSON.stringify({ phone: loginUser.phone, otpNum: otp }),
    })
      .then((res) => res.json())
      .then((data) => {
       
        if (data.success) {
        
            setLoginUser({
            ...loginUser, token:data.data.token ,   role: data?.data?.user.role,
          //  phone:data?.data?.user.phone,
         
          //   fullName: data?.data.user.fullName,
          //   email: data?.data?.user.email,
          //   address: data?.data?.user.phone,
          //   profilepic: data?.data?.user.profilePic,
          //   personalCode: data?.data?.user.personalCode,
          });
         
          setSnack({
            open: true,
            message: data.message,
            severity: "success",
          });
        } else {
          setSnack({
            open: true,
            message: data.message,
            severity: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setSnack({
          open: true,
          message: "خطایی رخ داده است",
          severity: "error",
        });
      });
  };
 

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    loginUser && localStorage.setItem("loginUser", JSON.stringify(loginUser));
  
  }, [loginUser]);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    const rememberedCredentials = JSON.parse(
      localStorage.getItem("rememberedCredentials")
    );
    if (
      rememberedCredentials &&
      rememberedCredentials.personalCode &&
      rememberedCredentials.password
    ) {
      setpersonalCode(rememberedCredentials.personalCode);
      setPassword(rememberedCredentials.password);
      setChecked(true);
    }
  }, []);

  useEffect(() => {
    if (checked) {
      localStorage.setItem(
        "rememberedCredentials",
        JSON.stringify({ personalCode, password })
      );
    } else {
      localStorage.removeItem("rememberedCredentials");
    }
  }, [checked, personalCode, password]);
  useEffect(() => {
    const copyToClipboard = async () => {
    
        try {
          await navigator.clipboard.writeText(otp);
          console.log('کد ورود: ', otp);
        } catch (err) {
          console.error('خطا در کپی کردن متن', err);
        }
    
    };
  
   
  
      copyToClipboard();
   
  }, [otp]);
  
  
  
  
  if (loginUser?.token ) {
    return <Navigate to="/panel" />;
  }
  //  else if (
  //   loginUser?.token &&
  //   (loginUser.role === "admin" || loginUser.role === "superAdmin")
  // ) {
  //   return <Navigate to="/panel" />;
  // }

  return (
    
      <Box  sx={{
backgroundColor:"#306352",
width:"100%",
 height:"100vh"

      }} >
        <Stack
          alignItems="center"
          justifyContent="center"
          height="100vh"
          sx={{
            direction: "ltr",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            boxShadow: "3",
          }}
        >
          <Box
            component="form"
            sx={{
              display: "flex",
              backgroundColor: "whitesmoke",
              flexDirection: "column",
              boxShadow: "3",
              boxSizing: "border-box",
              border: "#ccc",
              borderRadius: "12px",
              "& legend": { textAlign: "left" },
              "& input": { direction: "ltr", textAlign: "center" },
              "@media (max-width:600px)": {
                maxWidth: "300px",
                "& .MuiTextField-root": {
                  fontSize: "0.875rem",
                },
                "& .MuiButton-root": {
                  fontSize: "0.875rem",
                  padding: "6px 8px",
                },
              },
            }}
            gap={3}
            width="100%"
            maxWidth="450px"
            p={3}
            mt={3}
          >
            <Typography
              component="h1"
              sx={{ direction: "rtl", textAlign: "center", fontWeight: "600" }}
            >
              ورود به حساب کاربری
            </Typography>
            <TextField
              name="personalCode"
              placeholder="نام کاربری"
              variant="outlined"
              required
              helperText="نام کاربری"
              sx={{
                "& .MuiFormHelperText-root": {
                  direction: "rtl",
                  textAlign: "right",
                },
              }}
              autoComplete="off"
              onChange={(e) => setpersonalCode(e.target.value)}
              value={personalCode}
              type="text"
              inputProps={{ pattern: "\\d*" }}
            />
            <TextField
              name="password"
              placeholder="گذرواژه"
              helperText="گذرواژه"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={password}
            />
            {/* <Box
              sx={{
                textAlign: "right",
                paddingRight: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "5",
              }}
            >
              <Typography variant="body1">مرا به یاد داشته باش</Typography>
              <FormControlLabel
                control={
                  <Checkbox checked={checked} onChange={handleCheckboxChange} />
                } 
              />
            </Box> */}

            {successLogin && (
              <TextField
                name="OTP"
                sx={{ border: "rgb(189,155,25)" }}
                label="کد تایید"
                value={otp}
                onChange={(e) => setOTP(e.target.value)}
              />
            )}
            {!successLogin && (
              <Button
                variant="contained"
                disableElevation
                size="large"
                color="warning"
                sx={{ backgroundColor: "rgb(189,155,25)" }}
                onClick={handleLogin}
              >
                ورود و دریافت کد
              </Button>
            )}

            {successLogin && (
              <Button
                variant="contained"
                disableElevation
                size="large"
                sx={{ backgroundColor: "rgb(189,155,25)" }}
                onClick={handleOTP}
              >
                ثبت کد
              </Button>
            )}
            <Button
              component={Link}
              to="/forget-password"
              variant="outlined"
              size="large"
              sx={{ color: "rgb(189,155,25)", border: "rgb(189,155,25)" }}
            >
              فراموشی گذرواژه؟
            </Button>
          </Box>
        </Stack>
        <Snackbar
          open={snack.open}
          autoHideDuration={6000}
          onClose={() => setSnack({ ...snack, open: false })}
        >
          <Alert
            onClose={() => setSnack({ ...snack, open: false })}
            severity={snack.severity}
          >
            {snack.message}
          </Alert>
        </Snackbar>
      </Box>
   
  );
}

export default Login;

import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Box, Grid, Typography, Button, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { Navigate } from 'react-router-dom';
import PanelSidebar from '../../Components/SideBar/sideBar';
import { AuthContext } from '../../utils/AuthContext';

function NewsUpdate() {
  const [newsData, setNewsData] = useState([]);
  const { id } = useParams();
  const { loginUser } = useContext(AuthContext);
  const [open, setOpen] = useState(false); // To handle the dialog open/close state
  const [currentNewsId, setCurrentNewsId] = useState(null); // Store current news id to delete

  useEffect(() => {
    fetch(`https://api.asiamehr.com/api/v1/news`)
      .then((res) => res.json())
      .then((data) => setNewsData(data.data.news))
      .catch((err) => console.log(err));
  }, []);

  const deleteItem = () => {
    fetch(`https://api.asiamehr.com/api/v1/news/${currentNewsId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loginUser.token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setNewsData(newsData.filter(news => news._id !== currentNewsId));
        setOpen(false); 
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteClick = (id) => {
    setCurrentNewsId(id); 
    setOpen(true); 
  };

  const handleCloseDialog = () => {
    setOpen(false); 
  };

  return (
    !loginUser?.token ? <Navigate to="/login" /> :
      <Box display="flex" flexDirection="column" alignItems="center" p={3} sx={{

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: "20px",
        direction: "rtl",
      }}>
        <Typography variant="h4" gutterBottom>
          حذف کردن اطلاعات
        </Typography>
        <PanelSidebar />
        <Grid container spacing={3} justifyContent="center">
          {newsData?.map(news => (
            <Grid item xs={12} sm={6} md={4} key={news._id} sx={{
              padding:"20px"
            }}>
              <Card >
                <CardMedia
                  component="img"
                  height="200"
                  image={`https://api.asiamehr.com${news.image}`}
                  alt={news.title}
                />
                <CardContent>
                  <Typography variant="h6">{news.title}</Typography>
                  <Typography variant="body2" color="text.secondary">{news.description}</Typography>
                </CardContent>
                <Button 
                  variant="contained" 
                  color="secondary" 
                  fullWidth 
                  onClick={() => handleDeleteClick(news._id)}>
                  حذف
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Confirmation Dialog */}
        <Dialog open={open} onClose={handleCloseDialog}>
          <DialogTitle>آیا مطمئن هستید؟</DialogTitle>
          <DialogContent>
            <Typography>آیا از حذف این خبر اطمینان دارید؟</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">لغو</Button>
            <Button onClick={deleteItem} color="secondary">حذف</Button>
          </DialogActions>
        </Dialog>
      </Box>
  );
}

export default NewsUpdate;

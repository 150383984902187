import React, { useState, useContext } from 'react';
import { Link, Navigate } from 'react-router-dom';
import {
  Snackbar,
  Alert,
  Select,
  MenuItem,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Box,
  Stack,
  Typography,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
} from '@mui/material';



import i18next from 'i18next';
import { AuthContext } from '../../utils/AuthContext';
import PanelSidebar from '../../Components/SideBar/sideBar';
const languages = [
  { code: "ar", label:  "عربی", flag: "sa" },
  { code: "en", label: "انگلیسی", flag: "us" },
];

const NewsPage = () => {
  const { loginUser } = useContext(AuthContext);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [text, setText] = useState('');
  const [mytext, setMytext] = useState([]); // For translations
  const [selectedLang, setSelectedLang] = useState("");
  const [inputTextTitle, setInputTextTitle] = useState(""); // For title translation
  const [inputTextDescription, setInputTextDescription] = useState("");
  const [inputText, setInputText] = useState(''); // For inputtext
  const [published, setPublished] = useState(false); // For published status
  const [alertOpen, setAlertOpen] = useState(false);
  const [category, setCategory] = useState('');
  const [datePost, setDatePost] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [file, setFile] = useState(null);
  const [imageURL, setImageURL] = useState('');
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  
  const isMobile = useMediaQuery('(max-width:900px)');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleImage1Change = (e) => {
    setImage1(e.target.files[0]);
  };

  const handleImage2Change = (e) => {
    setImage2(e.target.files[0]);
  };

  const handleAddTranslation = () => {
    if (!selectedLang || !inputTextTitle.trim() || !inputTextDescription.trim() || !inputText.trim()) {
      setAlertMessage("لطفا تمام فیلدهای ترجمه را پر کنید");
      setAlertSeverity("error");
      setAlertOpen(true);
      return;
    }

    const newTranslation = {
      lang: selectedLang,
      title: inputTextTitle,
      description: inputTextDescription,
      inputtext: inputText, // Add inputtext
      text: text, // Add text
      datePost: datePost, // Add datePost
      published: published, // Add published status
    };

    setMytext([...mytext, newTranslation]);
    setSelectedLang("");
    setInputTextTitle("");
    setInputTextDescription("");
    setInputText(""); // Reset inputtext
    setPublished(false); // Reset published status
  };

  const addNews = async (e) => {
    e.preventDefault();

    if (!file) {
      setAlertMessage("افزودن تصویر الزامی میباشد ");
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('file', file);
    formData.append('image1', image1);
    formData.append('image2', image2);
    formData.append('text', text);
    formData.append('description', description);
    formData.append('mytext', JSON.stringify(mytext));
    formData.append('category', category);
    formData.append('datePost', datePost);

    try {
      const response = await fetch('https://api.asiamehr.com/api/v1/news', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${loginUser.token}`,
        },
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        setAlertMessage('افزودن محتوا موفقیت آمیز');
        setAlertSeverity('success');
        setAlertOpen(true);
        setImageURL(data.data.image);
      } else {
        setAlertMessage('افزودن محتوا ناموفق');
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    } catch (error) {
      setAlertMessage('خطا در افزودن محتوا');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  return !loginUser?.token ? (
    <Navigate to="/login" />
  ) : (
    <Box
      sx={{
        minHeight: '100vh',
        background:
          'linear-gradient(180deg, rgba(0,31,63,1) 0%, rgba(0,81,165,1) 100%)',
      }}
    >
      <Stack
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="flex-end"
      >
        <PanelSidebar />
        <Stack
          sx={{
            width: isMobile ? '100%' : '80%',
            minHeight: '100vh',
            marginTop: 10,
            marginRight: isMobile ? 0 : 35,
            padding: isMobile ? 2 : 0,
          }}
        >
          <Box
            component="form"
            onSubmit={addNews}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: '100%',
              padding: '50px 5%',
              borderRadius: '20px',
              marginTop: '50px',
            }}
          >
            <Typography
              variant={isMobile ? 'h5' : 'h4'}
              my={'20px'}
              color={'gainsboro'}
              textAlign="center"
            >
              افزودن خبر جدید
            </Typography>

            <TextField
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="تیتر"
              fullWidth
              sx={{
                backgroundColor: '#fff',
                borderRadius: '10px',
                '& input': {
                  textAlign: 'right !important',
                  fontSize: '16px',
                },
                textAlign: 'right !important',
                direction: 'rtl',
                boxShadow: '0 0 10px 2px rgba(255,255,255,.6)',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                },
              }}
            />

            <input
              accept="image/*"
              type="file"
              onChange={handleFileChange}
              style={{ margin: '16px 0', display: 'block', color: '#fff' }}
              required
            />
            <input
              accept="image/*"
              type="file"
              onChange={handleImage1Change}
              style={{ margin: '16px 0', display: 'block', color: '#fff' }}
              required
            />
            <input
              accept="image/*"
              type="file"
              onChange={handleImage2Change}
              style={{ margin: '16px 0', display: 'block', color: '#fff' }}
              required
            />

            <TextField
              variant="outlined"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="متن "
              fullWidth
              sx={{
                backgroundColor: '#fff',
                borderRadius: '10px',
                '& input': {
                  textAlign: 'right !important',
                  fontSize: '16px',
                },
                textAlign: 'right !important',
                direction: 'rtl',
                boxShadow: '0 0 10px 2px rgba(255,255,255,.6)',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                },
              }}
            />

            <TextField
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="توضیحات"
              fullWidth
              multiline
              rows={4}
              sx={{
                backgroundColor: '#fff',
                borderRadius: '10px',
                '& textarea': {
                  textAlign: 'right !important',
                  fontSize: '16px',
                },
                textAlign: 'right !important',
                direction: 'rtl',
                boxShadow: '0 0 10px 2px rgba(255,255,255,.6)',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                },
              }}
            />

            <FormControl fullWidth sx={{ backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0 0 10px 2px rgba(255,255,255,.6)", direction: "rtl" }}>
              <InputLabel id="category-select-label" sx={{ color: "#000" }}>دسته‌بندی</InputLabel>
              <Select
                labelId="category-select-label"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                displayEmpty
                sx={{ "& .MuiInputBase-root": { fontSize: "16px", height: "56px", color: "#000" } }}
              >
                {["سیاست", "ورزش", "فناوری", "سلامت", "سرگرمی", "کسب و کار", "جهان"].map((cat) => (
                  <MenuItem key={cat} value={cat}>{cat}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              variant="outlined"
              value={datePost}
              onChange={(e) => setDatePost(e.target.value)}
              placeholder="تاریخ (مثال: ۱۴۰۲/۱۱/۲۰)"
              fullWidth
              sx={{ backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0 0 10px 2px rgba(255,255,255,.6)" }}
            />

            <FormControl fullWidth sx={{ backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0 0 10px 2px rgba(255,255,255,.6)", direction: "rtl" }}>
              <InputLabel id="language-select-label" sx={{ color: "#000" }}>انتخاب زبان</InputLabel>
              <Select
                labelId="language-select-label"
                value={selectedLang}
                onChange={(e) => setSelectedLang(e.target.value)}
                displayEmpty
                sx={{ "& .MuiInputBase-root": { fontSize: "16px", height: "56px", color: "#000" } }}
              >
                {languages?.map((lang) => (
                  <MenuItem key={lang.code} value={lang.code}>{lang.label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              variant="outlined"
              value={inputTextTitle}
              onChange={(e) => setInputTextTitle(e.target.value)}
              placeholder="عنوان ترجمه شده"
              fullWidth
              sx={{ backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0 0 10px 2px rgba(255,255,255,.6)" }}
            />

            <TextField
              variant="outlined"
              value={inputTextDescription}
              onChange={(e) => setInputTextDescription(e.target.value)}
              placeholder="توضیحات ترجمه شده"
              fullWidth
              multiline
              rows={2}
              sx={{ backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0 0 10px 2px rgba(255,255,255,.6)" }}
            />

            <TextField
              variant="outlined"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              placeholder="متن ترجمه شده"
              fullWidth
              sx={{ backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0 0 10px 2px rgba(255,255,255,.6)" }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={published}
                  onChange={(e) => setPublished(e.target.checked)}
                  color="primary"
                />
              }
              label="منتشر شده"
              sx={{ color: '#fff' }}
            />

            <Button variant="contained" color="primary" onClick={handleAddTranslation}>
              افزودن ترجمه
            </Button>

            {mytext?.map((text, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: '#1a2e36',
                  padding: 2,
                  marginBottom: 2,
                  borderRadius: '10px',
                  color: '#fff',
                }}
              >
                <Typography variant="h6">
                  {languages.find((lang) => lang.code === text.lang)?.label}:
                </Typography>
                <Typography>عنوان: {text.title}</Typography>
                <Typography>توضیحات: {text.description}</Typography>
                <Typography>متن: {text.inputtext}</Typography>
                <Typography>تاریخ: {text.datePost}</Typography>
                <Typography>وضعیت انتشار: {text.published ? 'منتشر شده' : 'منتشر نشده'}</Typography>
              </Box>
            ))}

            <Button
              variant="contained"
              type="submit"
              fullWidth
              sx={{
                backgroundColor: '#27632a',
                height: '56px',
                borderRadius: '10px',
                fontSize: '16px',
                boxShadow: '0 0 10px 2px rgba(255,255,255,.6)',
              }}
            >
              اضافه کردن محتوا
            </Button>

            {imageURL && (
              <Box mt={4}>
                <Typography variant="h6" color="white">
                  تصویر بارگذاری شده:
                </Typography>
                <img
                  src={`https://api.asiamehr.com${imageURL}`}
                  alt="Uploaded Gallery"
                  style={{ maxWidth: '100%', borderRadius: '10px' }}
                />
              </Box>
            )}

            <Link to="delete" style={{ marginTop: '20px', color: '#fff' }}>
              حذف کردن محتوا
            </Link>

            <Snackbar
              open={alertOpen}
              autoHideDuration={6000}
              onClose={handleCloseAlert}
            >
              <Alert
                onClose={handleCloseAlert}
                severity={alertSeverity}
                sx={{ width: '100%' }}
              >
                {alertMessage}
              </Alert>
            </Snackbar>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default NewsPage;

import { Stack } from '@mui/material';
import React from 'react';
import { HashLoader } from 'react-spinners';

export default function Loader() {
    return (
        <Stack 
            width={'100%'} 
            height={'100vh'} 
            alignItems={'center'} 
            justifyContent={'center'} 
            zIndex={99999}
            position="fixed"
            top={0}
            left={0}
            bgcolor="rgba(0, 0, 0, 0.6)"
        >
            <HashLoader size={80} color={"#ffffff"} loading={true} />
        </Stack>
    )
}

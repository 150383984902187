import React, { useState, useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import {
  Snackbar,
  Alert,
  Select,
  MenuItem,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Box,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

// import "../../Components/Pane/panel.css";
import i18next from 'i18next';
import { AuthContext } from "../../utils/AuthContext";
import PanelSidebar from "../../Components/SideBar/sideBar";
const languages = [
  { code: "ar", label: "عربی", flag: "sa" },
  { code: "en", label: "انگلیسی", flag: "us" },
];

const SliderPanel = () => {
  const { loginUser } = useContext(AuthContext);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [mytext, setMytext] = useState([]); // For translations
  const [selectedLang, setSelectedLang] = useState("");
  const [inputTextTitle, setInputTextTitle] = useState(""); // For title translation
  const [inputTextDescription, setInputTextDescription] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [file, setFile] = useState(null);
  const [imageURL, setImageURL] = useState("");

  const isMobile = useMediaQuery("(max-width:900px)");

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

    const handleAddTranslation = () => {
      if (!selectedLang || !inputTextTitle.trim() || !inputTextDescription.trim()) {
        setAlertMessage("لطفا تمام فیلدهای ترجمه را پر کنید");
        setAlertSeverity("error");
        setAlertOpen(true);
        return;
      }
          const newTranslation = {
        lang: selectedLang,
        title: inputTextTitle,
        description: inputTextDescription,
      };
    
      setMytext([...mytext, newTranslation]);
      setSelectedLang("");
      setInputTextTitle("");
      setInputTextDescription("");
    };
 

  const addSlider = async (e) => {
    e.preventDefault();
  
    if (!file) {
      setAlertMessage("افزودن تصویر الزامی میباشد ");
      setAlertSeverity("error");
      setAlertOpen(true);
      return;
    }
  
    const formData = new FormData();
    formData.append("title", title);
    formData.append("file", file);
    formData.append("description", description);
  
    // Append each translation individually
    formData.append("mytext", JSON.stringify(mytext)); 
  
    try {
      const response = await fetch("https://api.asiamehr.com/api/v1/slider", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loginUser.token}`,
        },
        body: formData,
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setAlertMessage("افزودن محتوا موفقیت آمیز");
        setAlertSeverity("success");
        setAlertOpen(true);
        setImageURL(data.data.image);
      } else {
        setAlertMessage("افزودن محتوا ناموفق");
        setAlertSeverity("error");
        setAlertOpen(true);
      }
    } catch (error) {
      setAlertMessage("خطا در افزودن محتوا");
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };
  

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  return !loginUser?.token ? (
    <Navigate to="/login" />
  ) : (
    <Box
      sx={{
        minHeight: "100vh",
        background:
          "linear-gradient(180deg, rgba(0,31,63,1) 0%, rgba(0,81,165,1) 100%)",
      }}
    >
      <Stack
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="flex-end"
      >
        <PanelSidebar />
        <Stack
          sx={{
            width: isMobile ? "100%" : "80%",
            minHeight: "100vh",
            marginTop: 10,
            marginRight: isMobile ? 0 : 35,
            padding: isMobile ? 2 : 0,
          }}
        >
          <Box
            component="form"
            onSubmit={addSlider}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
              padding: "50px 5%",
              borderRadius: "20px",
              marginTop: "50px",
            }}
          >
            <Typography
              variant={isMobile ? "h5" : "h4"}
              my={"20px"}
              color={"gainsboro"}
              textAlign="center"
            >
              افزودن محتوا به اسلایدر
            </Typography>

            <TextField
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="تیتر"
              fullWidth
             
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                "& input": {
                  textAlign: "right !important",
                  fontSize: "16px",
                },
                textAlign: "right !important",
                direction: "rtl",
                boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
            />

            <input
              accept="image/*"
              type="file"
              onChange={handleFileChange}
              style={{ margin: "16px 0", display: "block", color: "#fff" }}
              required
            />

            <TextField
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="توضیحات"
              fullWidth
              multiline
              rows={4}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                "& textarea": {
                  textAlign: "right !important",
                  fontSize: "16px",
                },
                textAlign: "right !important",
                direction: "rtl",
                boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
            />

<FormControl fullWidth sx={{ backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0 0 10px 2px rgba(255,255,255,.6)", direction: "rtl" }}>
  <InputLabel id="language-select-label" sx={{ color: "#000" }}>انتخاب زبان</InputLabel>
  <Select
    labelId="language-select-label"
    value={selectedLang}
    onChange={(e) => setSelectedLang(e.target.value)}
    displayEmpty
    sx={{ "& .MuiInputBase-root": { fontSize: "16px", height: "56px", color: "#000" } }}
  >
    {languages.map((lang) => (
      <MenuItem key={lang.code} value={lang.code}>{lang.label}</MenuItem>
    ))}
  </Select>
</FormControl>

<TextField
  variant="outlined"
  value={inputTextTitle}
  onChange={(e) => setInputTextTitle(e.target.value)}
  placeholder="عنوان ترجمه شده"
  fullWidth
  sx={{ backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0 0 10px 2px rgba(255,255,255,.6)" }}
/>

<TextField
  variant="outlined"
  value={inputTextDescription}
  onChange={(e) => setInputTextDescription(e.target.value)}
  placeholder="توضیحات ترجمه شده"
  fullWidth
  multiline
  rows={2}
  sx={{ backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0 0 10px 2px rgba(255,255,255,.6)" }}
/>

<Button variant="contained" color="primary" onClick={handleAddTranslation}>
  افزودن ترجمه
</Button>

            {mytext.map((text, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: "#1a2e36",
                  padding: 2,
                  marginBottom: 2,
                  borderRadius: "10px",
                  color: "#fff",
                }}
              >
                <Typography variant="h6">{languages.find(lang => lang.code === text.lang).label}:</Typography>
                <Typography>{text.inputTextTitle}</Typography>
                <Typography>{text.inputTextDescription}</Typography>
              </Box>
            ))}

            <Button
              variant="contained"
              type="submit"
              fullWidth
              sx={{
                backgroundColor: "#27632a",
                height: "56px",
                borderRadius: "10px",
                fontSize: "16px",
                boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
              }}
            >
              افزودن اسلاید
            </Button>
            <Link to='delete' style={{ marginTop: '20px', color: '#fff' }}  >حذف کردن محتوا</Link>
            <Snackbar
              open={alertOpen}
              autoHideDuration={6000}
              onClose={handleCloseAlert}
            >
              <Alert
                onClose={handleCloseAlert}
                severity={alertSeverity}
                sx={{ width: "100%" }}
              >
                {alertMessage}
              </Alert>
            </Snackbar>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default SliderPanel;

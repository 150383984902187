import React, { useState, useEffect, useMemo } from "react";

import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import "./App.css";
import Dashboard from "./Components/Dashboard/Dashboard";
import SliderPanel from "./Pages/SliderManage/SliderPanel";
import SliderChange from "./Pages/SliderManage/deletSlider";
import Cart from "./Pages/CartManage/CartPanel";
import CartChange from "./Pages/CartManage/CartUpdate";
import AdminGallery from "./Pages/GalleryManage/AdminGallery";
import Users from "./Pages/UserManage/Users";
import JobApplies from "./Pages/JobAppManage/JobApplies";
import JobPanel from "./Pages/JobAppManage/jobPanel";
import JobUpdate from "./Pages/JobAppManage/JobUpdate";
import ArticlePanel from "./Pages/ArticleManage/Article";
import NewsPage from "./Pages/NewsManage/News";
import NewsUpdate from "./Pages/NewsManage/newsUpdate";
import ArticleUpdate from "./Pages/ArticleManage/ArticleUpdate";
import GetAllLetter from "./Pages/ExternalLetter/getAll";
import ReceiveLetter from "./Pages/ExternalLetter/recieve";
import TerminatedLetter from "./Pages/ExternalLetter/terminated";
import CreateExternalLetter from "./Pages/ExternalLetter/create";
import UserRegister from "./Pages/UserManage/UserRegister";
import Projectt from "./Pages/ProjectManage/Project";
import ProjectUpdate from "./Pages/ProjectManage/ProjectUpdate";
import GalleryPageUpdate from "./Pages/GalleryPageManage/GalleryPageUpdate";
import GalleryPage from "./Pages/GalleryPageManage/GalleryPage";
import GalleryUpdate from "./Pages/GalleryManage/GalleryUpdate";
import ExternalSendLetter from "./Pages/ExternalLetter/Send";
import ArchiveExternalLetter from "./Pages/ExternalLetter/Archive";
import GetOneLetter from "./Pages/ExternalLetter/getOne";
import { Toaster } from "react-hot-toast";
import CreateEnternalLetter from "./Pages/internalLetter/create";
import GetAllInternalLetter from "./Pages/internalLetter/getAll";
import ReceiveInternalLetter from "./Pages/internalLetter/recieve";
import GetOneInternalLetter from "./Pages/internalLetter/getOne";
import ExternalSearch from "./Pages/ExternalLetter/Search";
import InternalSearch from "./Pages/internalLetter/Search";

import EnternalSendLetter from "./Pages/internalLetter/Send";
import GetAllTranscript from "./Pages/Transcript/getAll";
import SentTransscripte from "./Pages/Transcript/sent";
import Recivedtranscript from "./Pages/Transcript/received";
import CreateProcess from "./Pages/Process/create";
import UpdateProcess from "./Pages/Process/update";
import GetOneProcess from "./Pages/Process/getOne";
import CreateRequest from "./Pages/Request/Create";
import MyRequest from "./Pages/Request/MyRequest";
import AllRequests from "./Pages/Request/AllRequests";
import CompleteRequests from "./Pages/Request/Complete";
import PendingRequest from "./Pages/Request/Pending";
import RequestSearch from "./Pages/Request/Search";
import Error404 from "./Pages/Erorr/Error";
import { AuthContext } from "./utils/AuthContext";
import EditProfile from "./Pages/UserManage/EditProfile";
import Login from "./Pages/Auth/Login";
import MyForgotPassword from "./Pages/Auth/Pass";
import Employees from "./Components/employepanel/Employe";
import Panel from "./Components/employepanel/EmployeePanel";
import EditUserProfile from "./Pages/UserManage/EditUserProfile";


function App() {

  // const { user, login, logout } = useContext(AuthContext);
  const [loginUser, setLoginUser] = useState(null);

  useEffect(() => {
   setLoginUser();
  }, []);


  return (
    <div>


      <AuthContext.Provider value={{ loginUser, setLoginUser }}>
        <BrowserRouter>
          <Routes>
       
            <Route path="/" exact={true} element={<Login/>} /> 
            <Route path="/panel" exact={true} element={<Dashboard />} />

            <Route
              path="/panel/slider"
              exact={true}
              element={<SliderPanel />}
            />
            <Route
              path="/panel/slider/delete"
              exact={true}
              element={<SliderChange />}
            />
            <Route path="/panel/cart" exact={true} element={<Cart />} />
            <Route
              path="/panel/cart/delete"
              exact={true}
              element={<CartChange />}
            />
            <Route
              path="/panel/gallery"
              exact={true}
              element={<AdminGallery />}
            />
            <Route
              path="/panel/gallery/delete"
              exact={true}
              element={<GalleryUpdate/>}
            />

            <Route path="/panel/users" exact={true} element={<Users />} />
            {/* <Route
              path="/panel/sendemail"
              exact={true}
              element={<EmailForm />}
            /> */}
            {/* <Route path="/panel/profile" exact={true} element={<EditProfile />} /> */}

            <Route path="/panel/profile" exact={true} element={<EditProfile />} />
            <Route path="/login" exact={true} element={<Login/>} />
            <Route path="/forget-password" exact={true} element={<MyForgotPassword />} />
            <Route path="/panel/applies" exact={true} element={<JobApplies />} />
            <Route path="/panel/job" exact={true} element={<JobPanel />} />
            <Route path="/panel/job/delete" exact={true} element={<JobUpdate />} />
            <Route path="/panel/article" exact={true} element={<ArticlePanel />} />
            <Route path="/panel/news" exact={true} element={<NewsPage />} />
            <Route path="/panel/news/delete" exact={true} element={<NewsUpdate />} />
            <Route path="/panel/article/delete" exact={true} element={<ArticleUpdate />} />
            <Route path="/panel/profile" exact={true} element={<EditProfile />} />
            <Route path="/employee" exact={true} element={<Panel />} /> 
            {/* <Route path="/user/:id" element={<UserDetails />} /> */}
            <Route path="/user/profile/:id" element={<EditUserProfile />} />

            {loginUser?.token && (
                <Route path="/user/:id/edit" element={<EditUserProfile />} />
            )}
            {loginUser?.token && (
              <Route path="/panel/external-letter">
                <Route
                  path="all"
                  element={
                    loginUser?.role !== "admin" &&
                      loginUser?.role !== "superAdmin" ? (
                      <Navigate to={"/panel/external-letter/sent"} />
                    ) : (
                      <GetAllLetter />
                    )
                  }
                />
                <Route
                  path="receive"
                  element={
                    loginUser?.role !== "admin" &&
                      loginUser?.role !== "superAdmin" ? (
                      <Navigate to={"/panel/external-letter/sent"} />
                    ) : (
                      <ReceiveLetter />
                    )
                  }
                />
                <Route path="terminated" element={<TerminatedLetter />} />
                <Route path="sent" element={<ExternalSendLetter />} />
                <Route
                  path="archive"
                  element={
                    loginUser?.role !== "admin" &&
                      loginUser?.role !== "superAdmin" &&
                      loginUser?.role !== "archive" ? (
                      <Navigate to={"/panel/external-letter/sent"} />
                    ) : (
                      <ArchiveExternalLetter />
                    )
                  }
                />
                <Route path=":id" element={<GetOneLetter />} />
                <Route path="add" element={<CreateExternalLetter />} />
                <Route path="search" element={<ExternalSearch />} />
              </Route>
            )}
            {loginUser?.token && (
              <Route path="/panel/internal-letter">
                <Route path="sent" element={<EnternalSendLetter />} />
                <Route
                  path="all"
                  element={
                    loginUser?.role !== "admin" &&
                      loginUser?.role !== "superAdmin" ? (
                      <Navigate to={"/panel/internal-letter/sent"} />
                    ) : (
                      <GetAllInternalLetter />
                    )
                  }
                />
                <Route path="receive" element={<ReceiveInternalLetter />} />
                <Route path="add" element={<CreateEnternalLetter />} />
                <Route path=":id" element={<GetOneInternalLetter />} />
                <Route path="search" element={<InternalSearch />} />
              </Route>
            )}
            {loginUser?.token && (
              <Route path="/panel/transcript">
                <Route
                  path="all"
                  element={
                    loginUser?.role !== "admin" &&
                      loginUser?.role !== "superAdmin" ? (
                      <Navigate to={"/panel/transcript/sent"} />
                    ) : (
                      <GetAllTranscript />
                    )
                  }
                />
                <Route path="sent" element={<SentTransscripte />} />
                <Route path="receive" element={<Recivedtranscript />} />
              </Route>
            )}
            {loginUser?.token && (
              <Route path="/panel/process">
                <Route path="create" element={<CreateProcess />} />
                <Route path="update" element={<UpdateProcess />} />
                <Route path=":id" element={<GetOneProcess />} />
              </Route>
            )}
            {loginUser?.token && (
              <Route path="/panel/request">
                <Route path="create" element={<CreateRequest />} />
                <Route path="all" element={<AllRequests />} />
                <Route path="pending" element={<PendingRequest />} />
                <Route path="complete" element={<CompleteRequests />} />
                <Route path="myRequest" element={<MyRequest />} />
                <Route path="search" element={<RequestSearch />} />
              </Route>
            )}
            {/* <Route path="/gallery" exact={true} element={<Gallery />} />
            <Route path="/galleryG" exact={true} element={<GalleryUpdate />} /> */}
            {/* <Route path="/employee" exact={true} element={<EmployeePanel />} /> */}

            <Route path="/panel/gallerypage" element={<GalleryPage />} />
            <Route
              path="/panel/gallerypage/delete"
              element={<GalleryPageUpdate />}
            />
            <Route path="/panel/projectt" element={<Projectt />} />
            {/* <Route path="/panel/permission" element={<AdminPermissions />} /> */}
            <Route path="/panel/projectt/delete" element={<ProjectUpdate />} />
            {/* <Route path="/panel/gallery/deletepic" element={<GalleryG/>} /> */}
            <Route path="/panel/register" element={<UserRegister />} />
            <Route path="*" element={<Error404 />} />

          </Routes>
          <Toaster />
        </BrowserRouter>
      </AuthContext.Provider>


    </div>
  );
}

export default App;

import React, { useState, useContext } from 'react';

import { Box, TextField, Button, Stack, Typography, IconButton, Snackbar, Alert, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

import { AuthContext } from '../../utils/AuthContext';
import PanelSidebar from '../../Components/SideBar/sideBar';

function EditProfile() {
  const { id } = useParams();
  const { loginUser, setLoginUser } = useContext(AuthContext);
  
  const [userData, setUserData] = useState({
    fullName: loginUser?.fullName || '',
    email: loginUser?.email || '',
    password: loginUser?.password || '',
    address: loginUser?.address || '',
    phone: loginUser?.phone || '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [snack, setSnack] = useState({ open: false, message: '', severity: 'success' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch(`https://api.asiamehr.com/api/v1/user/profile/${loginUser.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${loginUser.token}`,
        },
        body: JSON.stringify({
          fullName: userData.fullName,
          email: userData.email,
          password: userData.password,
          address: userData.address,
         
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
  
        // Update user details without changing the token
        const updatedUser = {
          ...loginUser,
          fullName: data?.data?.fullName || loginUser.fullName,
          email: data?.data?.email || loginUser.email,
          address: data?.data?.address || loginUser.address,
          phone: data?.data?.phone || loginUser.phone,
        };
  
        setLoginUser(updatedUser);
        localStorage.setItem('user', JSON.stringify(updatedUser));
  
        setSnack({ open: true, message: 'پروفایل با موفقیت بروزرسانی شد', severity: 'success' });
      } else {
        const errorData = await response.json();
        setSnack({ open: true, message: errorData.message || 'بروزرسانی پروفایل ناموفق بود', severity: 'error' });
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setSnack({ open: true, message: 'خطایی رخ داده است', severity: 'error' });
    }
  };
  

  return (
    <Box sx={{  display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center', 
      width:"100%",
      height:"100vh",
      background:
      "linear-gradient(180deg, rgba(0,31,63,1) 0%, rgba(0,81,165,1) 100%)",
      
      }}>
      <PanelSidebar />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 3,
          maxWidth: '500px',
        
          margin: 'auto',
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: 'background.paper',
          
        }}
      >
        <Typography variant="h5" mb={3}>ویرایش پروفایل</Typography>

        <Stack spacing={2} width="100%">
       
          <TextField
            label="ایمیل"
            name="email"
            value={userData.email}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label="رمز عبور"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={userData.password}
            onChange={handleInputChange}
            fullWidth
            placeholder="حداقل 8 کاراکتر"
            inputProps={{ minLength: 8 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="آدرس"
            name="address"
            value={userData.address}
            onChange={handleInputChange}
            fullWidth
          />
     
        </Stack>

        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ marginTop: 3 }}>
          ذخیره تغییرات
        </Button>
      </Box>

      <Snackbar open={snack.open} autoHideDuration={4000} onClose={() => setSnack({ ...snack, open: false })}>
        <Alert severity={snack.severity} onClose={() => setSnack({ ...snack, open: false })}>
          {snack.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default EditProfile;

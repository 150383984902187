import React, { useState, useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import {
  Snackbar,
  Alert,
  Select,
  MenuItem,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Box,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ReactQuill from "react-quill";
import i18next from "i18next";
import { AuthContext } from "../../utils/AuthContext";
import ShadowDomComponent from "../../Components/ShadowDom";
import PanelSidebar from "../../Components/SideBar/sideBar";

const languages = [
  { code: "ar", label: "عربی", flag: "sa" },
  { code: "en", label: "انگلیسی", flag: "us" },
];

const ArticlePanel = () => {
  const { loginUser } = useContext(AuthContext);
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [mainTitle, setMainTitle] = useState("");
  const [content, setContent] = useState("");
  const [description, setDescription] = useState("");
  const [mytext, setMytext] = useState([]);
  const [selectedLang, setSelectedLang] = useState("");
  const [inputTextTitle, setInputTextTitle] = useState("");
  const [inputTextSummary, setInputTextSummary] = useState("");
  const [inputTextContent, setInputTextContent] = useState("");
  const [inputTextDescription, setInputTextDescription] = useState("");
  const [inputTextMainTitle, setInputTextMainTitle] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [file, setFile] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [secImage, setSecImage] = useState(null);
  const [thimage, setThimage] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const handleDescriptionChange = (value) => {
    setDescription(value);
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ size: ["small", false, "large", "huge"] }],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }], 
    ],
    clipboard: {
   
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "align",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "direction",
  ];
  const isMobile = useMediaQuery("(max-width:900px)");

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handlePdfChange = (e) => {
    setPdf(e.target.files[0]);
  };
  const handleSecImageChange = (e) => {
    setSecImage(e.target.files[0]);
  };
  const handleThImageChange = (e) => {
    setThimage(e.target.files[0]);
  };
  const handleAddTranslation = () => {
    if (!selectedLang || !inputTextTitle.trim() || !inputTextSummary.trim() || !inputTextContent.trim() || !inputTextDescription.trim() || !inputTextMainTitle.trim()) {
      setAlertMessage("لطفا تمام فیلدهای ترجمه را پر کنید");
      setAlertSeverity("error");
      setAlertOpen(true);
      return;
    }

    const newTranslation = {
      lang: selectedLang,
      title: inputTextTitle,
      summary: inputTextSummary,
      content: inputTextContent,
      description: inputTextDescription,
      mainTitle: inputTextMainTitle,
    };

    setMytext([...mytext, newTranslation]);
    setSelectedLang("");
    setInputTextTitle("");
    setInputTextSummary("");
    setInputTextContent("");
    setInputTextDescription("");
    setInputTextMainTitle("");
  };

  const addArticle = async (e) => {
    e.preventDefault();

    if (!file) {
      setAlertMessage("افزودن تصویر الزامی میباشد ");
      setAlertSeverity("error");
      setAlertOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("summary", summary);
    formData.append("mainTitle", mainTitle);
    formData.append("content", content);
    formData.append("description", description);
    formData.append("file", file);
    formData.append("pdf", pdf);
    formData.append('mytext', JSON.stringify(mytext));

    try {
      const response = await fetch("https://api.asiamehr.com/api/v1/article", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loginUser.token}`,
        },
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        setAlertMessage("افزودن محتوا موفقیت آمیز");
        setAlertSeverity("success");
        setAlertOpen(true);
        setImageURL(data.data.image);
      } else {
        setAlertMessage("افزودن محتوا ناموفق");
        setAlertSeverity("error");
        setAlertOpen(true);
      }
    } catch (error) {
      setAlertMessage("خطا در افزودن محتوا");
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  if (loginUser?.role !== "admin") {
    return <Navigate to="/unauthorized" />;
  }

  return !loginUser?.token ? (
    <Navigate to="/login" />
  ) : (
    <Box
      sx={{
        minHeight: "100vh",
        background:
          "linear-gradient(180deg, rgba(0,31,63,1) 0%, rgba(0,81,165,1) 100%)",
      }}
    >
      <Stack flexDirection={isMobile ? "column" : "row"} justifyContent="flex-end">
        <PanelSidebar/>
        <Stack
          sx={{
            width: isMobile ? "100%" : "80%",
            minHeight: "100vh",
            marginTop: 10,
            marginRight: isMobile ? 0 : 35,
            padding: isMobile ? 2 : 0,
          }}
        >
          <Box
            component="form"
            onSubmit={addArticle}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
              padding: "50px 5%",
              borderRadius: "20px",
              marginTop: "50px",
            }}
          >
            <Typography
              variant={isMobile ? "h5" : "h4"}
              my={"20px"}
              color={"gainsboro"}
              textAlign="center"
            >
              افزودن محتوا به مقاله
            </Typography>

            <TextField
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="عنوان"
              fullWidth
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                "& input": {
                  textAlign: "right !important",
                  fontSize: "16px",
                },
                textAlign: "right !important",
                direction: "rtl",
                boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
            />

            <input
              accept="image/*"
              type="file"
              onChange={handleFileChange}
              style={{ margin: "16px 0", display: "block", color: "#fff" }}
              required
            />
               <input
              accept="image/*"
              type="file"
              onChange={handleSecImageChange}
              style={{ margin: "16px 0", display: "block", color: "#fff" }}
              required
            />
             <input
              accept="image/*"
              type="file"
              onChange={handleThImageChange}
              style={{ margin: "16px 0", display: "block", color: "#fff" }}
              required
            />
             <input
            
              type="file"
              onChange={handlePdfChange}
              style={{ margin: "16px 0", display: "block", color: "#fff" }}
              required
            /> pdf

            <TextField
              variant="outlined"
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              placeholder="خلاصه"
              fullWidth
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                "& input": {
                  textAlign: "right !important",
                  fontSize: "16px",
                },
                textAlign: "right !important",
                direction: "rtl",
                boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
              }}
            />

            <TextField
              variant="outlined"
              value={mainTitle}
              onChange={(e) => setMainTitle(e.target.value)}
              placeholder="عنوان اصلی"
              fullWidth
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                "& input": {
                  textAlign: "right !important",
                  fontSize: "16px",
                },
                textAlign: "right !important",
                direction: "rtl",
                boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
              }}
            />

            <TextField
              variant="outlined"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="محتوا"
              fullWidth
              multiline
              rows={4}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                "& textarea": {
                  textAlign: "right !important",
                  fontSize: "16px",
                },
                textAlign: "right !important",
                direction: "rtl",
                boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
              }}
            />

            {/* <TextField
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="توضیحات"
              fullWidth
              multiline
              rows={4}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                "& textarea": {
                  textAlign: "right !important",
                  fontSize: "16px",
                },
                textAlign: "right !important",
                direction: "rtl",
                boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
              }}
            /> */}
            <Box
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                padding: "10px",
                boxShadow: "0 0 10px rgba(0,0,0,.3)",
                minHeight: "300px", // حداقل ارتفاع برای CKEditor
              }}
            >
              <ShadowDomComponent> <ReactQuill
                value={description}
                onChange={handleDescriptionChange}
                placeholder="توضیحات"
                modules={modules}
                formats={formats}
                theme="snow"

              /></ShadowDomComponent>


            </Box>


            <FormControl fullWidth sx={{ backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0 0 10px 2px rgba(255,255,255,.6)", direction: "rtl" }}>
              <InputLabel id="language-select-label" sx={{ color: "#000" }}>انتخاب زبان</InputLabel>
              <Select
                labelId="language-select-label"
                value={selectedLang}
                onChange={(e) => setSelectedLang(e.target.value)}
                displayEmpty
                sx={{ "& .MuiInputBase-root": { fontSize: "16px", height: "56px", color: "#000" } }}
              >
                {languages.map((lang) => (
                  <MenuItem key={lang.code} value={lang.code}>{lang.label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              variant="outlined"
              value={inputTextTitle}
              onChange={(e) => setInputTextTitle(e.target.value)}
              placeholder="عنوان ترجمه شده"
              fullWidth
              sx={{ backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0 0 10px 2px rgba(255,255,255,.6)" }}
            />

            <TextField
              variant="outlined"
              value={inputTextSummary}
              onChange={(e) => setInputTextSummary(e.target.value)}
              placeholder="خلاصه ترجمه شده"
              fullWidth
              sx={{ backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0 0 10px 2px rgba(255,255,255,.6)" }}
            />

            <TextField
              variant="outlined"
              value={inputTextContent}
              onChange={(e) => setInputTextContent(e.target.value)}
              placeholder="محتوا ترجمه شده"
              fullWidth
              multiline
              rows={2}
              sx={{ backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0 0 10px 2px rgba(255,255,255,.6)" }}
            />
{/* 
            <TextField
              variant="outlined"
              value={inputTextDescription}
              onChange={(e) => setInputTextDescription(e.target.value)}
              placeholder="توضیحات ترجمه شده"
              fullWidth
              multiline
              rows={2}
              sx={{ backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0 0 10px 2px rgba(255,255,255,.6)" }}
            /> */}

<Box
  sx={{
    backgroundColor: "#f5f5f5",
    borderRadius: "10px",
    padding: "10px",
    boxShadow: "0 0 10px rgba(0,0,0,.3)",
    minHeight: "300px", // Minimum height for CKEditor (if using ReactQuill)
  }}
>
  <ShadowDomComponent>
    <ReactQuill
      value={inputTextDescription}
      onChange={setInputTextDescription}
      placeholder="توضیحات ترجمه شده"
      modules={modules}
      formats={formats}
      theme="snow"
    />
  </ShadowDomComponent>
</Box>

            <TextField
              variant="outlined"
              value={inputTextMainTitle}
              onChange={(e) => setInputTextMainTitle(e.target.value)}
              placeholder="عنوان اصلی ترجمه شده"
              fullWidth
              sx={{ backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0 0 10px 2px rgba(255,255,255,.6)" }}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleAddTranslation}
              sx={{
                width: "200px",
                alignSelf: "center",
                marginTop: "10px",
                backgroundColor: "#4caf50",
              }}
            >
              افزودن ترجمه
            </Button>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                width: "200px",
                alignSelf: "center",
                marginTop: "20px",
                backgroundColor: "#1976d2",
              }}
            >
              ذخیره مقاله
            </Button>
          </Box>

            <Link to="delete" style={{ marginTop: '20px', color: '#fff' }}>
              حذف کردن محتوا
            </Link>
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {alertMessage}
            </Alert>
          </Snackbar>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ArticlePanel;

import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Typography from "@mui/material/Typography";
import SpeedTwoToneIcon from "@mui/icons-material/SpeedTwoTone";
import EmailIcon from "@mui/icons-material/Email";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import ArchiveIcon from "@mui/icons-material/Archive";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import IosShareIcon from "@mui/icons-material/IosShare";
import {
  ListItemButton,
  Chip,
  Stack,
  Button,
  Avatar,
  Collapse,
  IconButton,
} from "@mui/material";
import LocalMallTwoToneIcon from "@mui/icons-material/LocalMallTwoTone";
import LocalPoliceTwoToneIcon from "@mui/icons-material/LocalPoliceTwoTone";
import CategoryTwoToneIcon from "@mui/icons-material/CategoryTwoTone";
import Inventory2TwoToneIcon from "@mui/icons-material/Inventory2TwoTone";
import BallotTwoToneIcon from "@mui/icons-material/BallotTwoTone";

import QueueTwoToneIcon from "@mui/icons-material/QueueTwoTone";
import Face2TwoToneIcon from "@mui/icons-material/Face2TwoTone";
import BadgeTwoToneIcon from "@mui/icons-material/BadgeTwoTone";
import GroupAddTwoToneIcon from "@mui/icons-material/GroupAddTwoTone";
import Diversity1TwoToneIcon from "@mui/icons-material/Diversity1TwoTone";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import GroupIcon from "@mui/icons-material/Group";
import SystemUpdateIcon from "@mui/icons-material/SystemUpdate";
import {
  EditNotifications,
  PeopleAlt,
  Person2Rounded,
} from "@mui/icons-material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { AuthContext } from "../../utils/AuthContext";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import BurstModeRoundedIcon from "@mui/icons-material/BurstModeRounded";
import CollectionsRoundedIcon from "@mui/icons-material/CollectionsRounded";
import NewspaperRoundedIcon from "@mui/icons-material/NewspaperRounded";
import ForwardToInboxRoundedIcon from "@mui/icons-material/ForwardToInboxRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import RecentActorsRoundedIcon from "@mui/icons-material/RecentActorsRounded";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import OutboxIcon from "@mui/icons-material/Outbox";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import CachedIcon from "@mui/icons-material/Cached";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import UpdateIcon from "@mui/icons-material/Update";
import VisibilityRounded from "@mui/icons-material/VisibilityRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
const drawerWidth = 240;

const PanelSidebar = ({ children, location }) => {
  const [mode, setMode] = useState("light");
  const theme = useTheme();
  const {  user} = useContext(AuthContext)
  const darkTheme = createTheme({
    palette: {
      mode: mode,
    },
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState({
    users: false,
    update: false,
    wage: false,
    inbox: false,
    externalLetter: false,
    internalLetter: false,
    transcript: false,
    process: false,
    request: false,
  });
  const { loginUser, setLoginUser } = useContext(AuthContext);
  const [data, setDate] = useState();
  const handleDrawerOpen = () => {
    setOpenMenu(true);
  };

  const handleDrawerClose = () => {
    setOpenMenu(false);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div style={{ display: "flex" }}>
        <AppBar
          position="fixed"
          sx={{
            width: "100%",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: mode === "light" ? "#2C3E50" : "#555555",
          }}
        >
          <Toolbar>
            {isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="end"
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h6" noWrap>
              داشبورد ادمین
            </Typography>
            <IconButton
              sx={{ ml: "auto" }}
              onClick={() => setMode(mode === "light" ? "dark" : "light")}
              color="inherit"
            >
              {mode === "light" ? <Brightness4Icon /> : <Brightness7Icon />}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant={isMobile ? "temporary" : "permanent"}
          anchor="right"
          open={isMobile ? openMenu : true}
          onClose={handleDrawerClose}
          sx={{
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor: mode === "light" ? "#ffffff" : "#424242",
              color: mode === "light" ? "#000000" : "#ffffff",
            },
            ...(isMobile && {
              "& .MuiBackdrop-root": {
                backgroundColor: "#34495E",
              },
            }),
          }}
        >
          <Toolbar />
          <List>
            <Stack justifyContent="center" alignItems="center" p={2} gap={2}>
              <Avatar
                src={
                  loginUser?.profilePic
                    ? `https://api.asiamehr.com${loginUser.profilePic}`
                    : "/default-avatar.png"
                }
                alt="User Profile Picture"
                sx={{ width: 100, height: 100 }}
              />
              <Typography component="p">
                خوش آمدید
                {loginUser?.fullName ? loginUser.fullName : loginUser?.role}
              </Typography>
              <Chip
                icon={<SupervisedUserCircleIcon />}
                label={loginUser?.email}
                color="warning"
                variant="filled"
                size="medium"
                sx={{
                  "& .MuiChip-label": { paddingRight: "0" },
                  "& .MuiChip-icon": { marginRight: "0px" },
                  justifyContent: "center",
                  textAlign: "center",
                }}
              />
            </Stack>
            <ListItemButton
              component={Link}
              to="/panel"
              selected={location?.pathname === "/panel"}
            >
              <ListItemIcon>
                <SpeedTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary="داشبورد" />
            </ListItemButton>
            <ListItemButton
              onClick={() =>
                setOpen({ ...open, userDetails: !open.userDetails })
              }
            >
              <ListItemIcon>
                <Person2Rounded />
              </ListItemIcon>
              <ListItemText primary="اطلاعات شخصی" />
              {open?.userDetails ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open?.userDetails} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton component={Link}
                
                to={`/user/profile/${loginUser?.id}`}
                >
                  <ListItemIcon>
                    <EditNotifications />
                  </ListItemIcon>
                  <ListItemText primary="ویرایش پروفایل" />
                </ListItemButton>
                {/* <ListItemButton component={Link}
                 to={`/user/${loginUser?.id}`}
                 >
                  <ListItemIcon>
                    <RecentActorsRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="نمایش پروفایل" />
                </ListItemButton> */}
              </List>
            </Collapse>

            {(loginUser?.role === "admin" ||
              loginUser?.role === "superAdmin") && (
              <>
                <ListItemButton
                  onClick={() => setOpen({ ...open, users: !open.users })}
                >
                  <ListItemIcon>
                    <Person2Rounded />
                  </ListItemIcon>
                  <ListItemText primary="کاربران" />
                  {open?.users ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open?.users} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton component={Link} to="/panel/register">
                      <ListItemIcon>
                        <BadgeTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="کاربر جدید" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/panel/users">
                      <ListItemIcon>
                        <RecentActorsRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary="همه کاربران" />
                    </ListItemButton>
                    {/* <ListItemButton component={Link} to="/panel/profile">
                  <ListItemIcon>
                    <RecentActorsRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="ویرایش صفحه کاربری " />
                </ListItemButton> */}
                  </List>
                </Collapse>
              </>
            )}

            <ListItemButton component={Link} to="/panel/profile">
              <ListItemIcon>
                <RecentActorsRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="ویرایش صفحه کاربری " />
            </ListItemButton>
            {(loginUser?.role === "admin" ||
              loginUser?.role === "superAdmin") && (
              <>
                <ListItemButton
                  onClick={() => setOpen({ ...open, update: !open.update })}
                >
                  <ListItemIcon>
                    <SystemUpdateIcon />
                  </ListItemIcon>
                  <ListItemText primary="بروز رسانی سایت" />
                  {open?.update ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open?.update} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton component={Link} to="/panel/slider">
                      <ListItemIcon>
                        <BurstModeRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary=" اسلایدر" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/panel/gallery">
                      <ListItemIcon>
                        <CollectionsRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary="گالری" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/panel/cart">
                      <ListItemIcon>
                        <CollectionsRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary=" کارت" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/panel/gallerypage">
                      <ListItemIcon>
                        <CollectionsRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary=" صفحه گالری" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/panel/projectt">
                      <ListItemIcon>
                        <CollectionsRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary=" صفحه پروژه" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/panel/news">
                      <ListItemIcon>
                        <CollectionsRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary="اخبار" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/panel/article">
                      <ListItemIcon>
                        <CollectionsRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary="مقالات" />
                    </ListItemButton>
                  </List>
                </Collapse>
              </>
            )}

            {(loginUser?.role === "admin" ||
              loginUser?.role === "superAdmin") && (
              <>
                <ListItemButton
                  onClick={() => setOpen({ ...open, jobs: !open?.jobs })}
                >
                  <ListItemIcon>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText primary="مدیریت مشاغل" />
                  {open?.jobs ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open?.jobs} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {/* Add Job */}
                    <ListItemButton component={Link} to="/panel/job">
                      <ListItemIcon>
                        <AddCircleOutlineRoundedIcon />
                      </ListItemIcon>

                      <ListItemText primary="افزودن درخواست شغلی" />
                    </ListItemButton>
                    {/* Delete Job */}
                    <ListItemButton component={Link} to="/panel/job/delete">
                      <ListItemIcon>
                        <DeleteRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary="حذف شغل" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/panel/applies">
                      <ListItemIcon>
                        <VisibilityRounded />
                      </ListItemIcon>
                      <ListItemText primary="مشاهده درخواست‌ها" />
                    </ListItemButton>
                  </List>
                </Collapse>
              </>
            )}

            {/* <ListItemButton
            onClick={() => setOpen({ ...open, wage: !open.wage })}
          >
            <ListItemIcon>
              <NewspaperRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="امور مالی" />
            {open?.wage ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton> */}
            {/* <Collapse in={open?.wage} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton component={Link} to="/panel/wage">
                <ListItemIcon>
                  <ReceiptLongIcon />
                </ListItemIcon>
                <ListItemText primary="ارسال فیش حقوقی" />
              </ListItemButton>
            </List>
          </Collapse> */}
            {/* {(loginUser?.role === "admin" ||
              loginUser?.role === "superAdmin") && (
              <>
                <ListItemButton
                  onClick={() => setOpen({ ...open, inbox: !open.inbox })}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="صندوق ورودی" />
                  {open?.inbox ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open?.inbox} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton component={Link} to="/panel/sendemail">
                      <ListItemIcon>
                        <ForwardToInboxRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary="ارسال ایمیل" />
                    </ListItemButton>
                  </List>
                </Collapse>
              </>
            )} */}

            <ListItemButton
              onClick={() =>
                setOpen({ ...open, externalLetter: !open.externalLetter })
              }
            >
              <ListItemIcon>
                <MarkunreadMailboxIcon />
              </ListItemIcon>
              <ListItemText primary="نامه های برون سازمانی" />
              {open?.externalLetter ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open?.externalLetter} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  component={Link}
                  to="/panel/external-letter/add"
                >
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary="ایجاد نامه" />
                </ListItemButton>
                {loginUser?.role == "admin" ||
                loginUser?.role == "superAdmin" ? (
                  <>
                    <ListItemButton
                      component={Link}
                      to="/panel/external-letter/all"
                    >
                      <ListItemIcon>
                        <AllInboxIcon />
                      </ListItemIcon>
                      <ListItemText primary="همه نامه ها" />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to="/panel/external-letter/receive"
                    >
                      <ListItemIcon>
                        <MarkunreadMailboxIcon />
                      </ListItemIcon>
                      <ListItemText primary="نامه های دریافتی" />
                    </ListItemButton>
                  </>
                ) : (
                  ""
                )}
                <ListItemButton
                  component={Link}
                  to="/panel/external-letter/sent"
                >
                  <ListItemIcon>
                    <OutboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="نامه های ارسالی" />
                </ListItemButton>

                <ListItemButton
                  component={Link}
                  to="/panel/external-letter/terminated"
                >
                  <ListItemIcon>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText primary="نامه های خاتمه یافته" />
                </ListItemButton>
                {loginUser?.role == "admin" ||
                loginUser?.role == "superAdmin" ||
                loginUser?.role == "archive" ? (
                  <ListItemButton
                    component={Link}
                    to="/panel/external-letter/archive"
                  >
                    <ListItemIcon>
                      <ArchiveIcon />
                    </ListItemIcon>
                    <ListItemText primary="بایگانی" />
                  </ListItemButton>
                ) : (
                  ""
                )}
                <ListItemButton
                  component={Link}
                  to="/panel/external-letter/search"
                >
                  <ListItemIcon>
                    <SearchIcon />
                  </ListItemIcon>
                  <ListItemText primary="جستجو" />
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton
              onClick={() =>
                setOpen({ ...open, internalLetter: !open.internalLetter })
              }
            >
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText primary="نامه های درون سازمانی" />
              {open?.internalLetter ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open?.internalLetter} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  component={Link}
                  to="/panel/internal-Letter/add"
                >
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary="ایجاد نامه" />
                </ListItemButton>
                {loginUser?.role == "admin" ||
                loginUser?.role == "superAdmin" ? (
                  <>
                    <ListItemButton
                      component={Link}
                      to="/panel/internal-Letter/all"
                    >
                      <ListItemIcon>
                        <AllInboxIcon />
                      </ListItemIcon>
                      <ListItemText primary="همه نامه ها" />
                    </ListItemButton>
                  </>
                ) : (
                  ""
                )}
                <ListItemButton
                  component={Link}
                  to="/panel/internal-Letter/receive"
                >
                  <ListItemIcon>
                    <MarkEmailReadIcon />
                  </ListItemIcon>
                  <ListItemText primary="نامه های دریافتی" />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="/panel/internal-Letter/sent"
                >
                  <ListItemIcon>
                    <OutboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="نامه های ارسالی" />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="/panel/internal-letter/search"
                >
                  <ListItemIcon>
                    <SearchIcon />
                  </ListItemIcon>
                  <ListItemText primary="جستجو" />
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton
              onClick={() => setOpen({ ...open, request: !open.request })}
            >
              <ListItemIcon>
                <IosShareIcon />
              </ListItemIcon>
              <ListItemText primary="درخواست" />
              {open?.request ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open?.request} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton component={Link} to="/panel/request/create">
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary="ایجاد درخواست" />
                </ListItemButton>
                {loginUser?.role == "admin" ||
                loginUser?.role == "superAdmin" ? (
                  <>
                    <ListItemButton component={Link} to="/panel/request/all">
                      <ListItemIcon>
                        <AllInboxIcon />
                      </ListItemIcon>
                      <ListItemText primary="همه درخواست ها" />
                    </ListItemButton>
                  </>
                ) : (
                  ""
                )}
                <ListItemButton component={Link} to="/panel/request/myRequest">
                  <ListItemIcon>
                    <OutboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="درخواست های من" />
                </ListItemButton>
                <ListItemButton component={Link} to="/panel/request/pending">
                  <ListItemIcon>
                    <HourglassBottomIcon />
                  </ListItemIcon>
                  <ListItemText primary="درخواست های در انتظار من" />
                </ListItemButton>
                <ListItemButton component={Link} to="/panel/request/complete">
                  <ListItemIcon>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText primary="درخواست های خاتمه یافته" />
                </ListItemButton>
                <ListItemButton component={Link} to="/panel/request/search">
                  <ListItemIcon>
                    <SearchIcon />
                  </ListItemIcon>
                  <ListItemText primary="جستجو" />
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton
              onClick={() => setOpen({ ...open, transcript: !open.transcript })}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="رونوشت ها" />
              {open?.transcript ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open?.transcript} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {loginUser?.role == "admin" ||
                loginUser?.role == "superAdmin" ? (
                  <ListItemButton component={Link} to="/panel/transcript/all">
                    <ListItemIcon>
                      <AllInboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="همه رونوشت ها" />
                  </ListItemButton>
                ) : null}

                <ListItemButton component={Link} to="/panel/transcript/receive">
                  <ListItemIcon>
                    <MarkEmailReadIcon />
                  </ListItemIcon>
                  <ListItemText primary="رونوشت دریافتی" />
                </ListItemButton>

                <ListItemButton component={Link} to="/panel/transcript/sent">
                  <ListItemIcon>
                    <OutboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="رونوشت ارسالی" />
                </ListItemButton>
              </List>
            </Collapse>
            {(loginUser?.role === "admin" ||
              loginUser?.role === "superAdmin") && (
              <>
                {" "}
                <ListItemButton
                  onClick={() => setOpen({ ...open, process: !open.process })}
                >
                  <ListItemIcon>
                    <CachedIcon />
                  </ListItemIcon>
                  <ListItemText primary="فرآیند" />
                  {open?.process ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open?.process} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton component={Link} to="/panel/process/create">
                      <ListItemIcon>
                        <LibraryAddIcon />
                      </ListItemIcon>
                      <ListItemText primary="ایجاد فرآیند" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/panel/process/update">
                      <ListItemIcon>
                        <UpdateIcon />
                      </ListItemIcon>
                      <ListItemText primary="بروزرسانی فرآیند" />
                    </ListItemButton>
                  </List>
                </Collapse>{" "}
              </>
            )}
          </List>
          <Button
            variant="outlined"
            onClick={() => {
              setLoginUser(null);
              localStorage.removeItem("loginUser");
              navigate("/login");
            }}
          >
            خروج
          </Button>
        </Drawer>
        <main>
          <Toolbar />
          {children}
        </main>
      </div>
    </ThemeProvider>
  );
};

export default PanelSidebar;

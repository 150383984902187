import React, { useEffect, useState, useContext } from 'react';
import { useParams, Navigate } from "react-router-dom";
import { Box, Button, Card, CardContent, CardMedia, Container, Grid, Typography, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { AuthContext } from '../../utils/AuthContext';
import PanelSidebar from '../../Components/SideBar/sideBar';

function GalleryUpdate() {
  const [galleryData, setGalleryData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { id } = useParams();
  const { loginUser } = useContext(AuthContext);

  useEffect(() => {
    fetch(`https://api.asiamehr.com/api/v1/gallery`)
      .then((res) => res.json())
      .then((data) => setGalleryData(data.data.gallery))
      .catch((err) => console.log(err));
  }, [id]);

  const handleDeleteConfirm = () => {
    fetch(`https://api.asiamehr.com/api/v1/gallery/${selectedId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loginUser.token}`,
      },
    })
      .then((res) => res.json())
      .then(() => {
        setGalleryData(galleryData.filter(gallery => gallery._id !== selectedId));
        setSnackbarMessage("آیتم با موفقیت حذف شد");
        setSnackbarOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setSnackbarMessage("خطا در حذف آیتم");
        setSnackbarOpen(true);
      });
    setDialogOpen(false);
  };

  const deleteItem = (id) => {
    setSelectedId(id);
    setDialogOpen(true);
  };

  return (
    !loginUser?.token ? <Navigate to="/login" /> :
      <Box sx={{ display: "flex", minHeight: "100vh", bgcolor: "#f5f5f5", justifyContent: "center", alignItems: "center" }}>
        {/* Main Content */}
        <Container sx={{ flex: 1, py: 4, textAlign: "center" }}>
          <Typography variant="h4" gutterBottom>
            حذف کردن اطلاعات
          </Typography>

          <Grid container spacing={3} justifyContent="center" alignItems="center">
            {galleryData?.map((gallery) => (
              <Grid item xs={12} sm={6} md={4} key={gallery._id}>
                <Card sx={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "center", textAlign: "center" }}>
                  <CardMedia
                    component="img"
                    height="180"
                    image={`https://api.asiamehr.com${gallery.image}`}
                    alt={gallery.title}
                    sx={{ objectFit: "cover" }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" gutterBottom>
                      {gallery.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {gallery.description}
                    </Typography>
                  </CardContent>
                  <Box sx={{ p: 2, textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="error"
                      fullWidth
                      onClick={() => deleteItem(gallery._id)}
                    >
                      حذف
                    </Button>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>


        <Box sx={{ width: 240, flexShrink: 0, bgcolor: "white", borderLeft: "1px solid #ddd", p: 2 }}>
          <PanelSidebar />
        </Box>


        <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)}>
          <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
        >
          <DialogTitle>حذف آیتم</DialogTitle>
          <DialogContent>
            <DialogContentText>
              آیا مطمئن هستید که می‌خواهید این آیتم را حذف کنید؟
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)} color="primary">
              انصراف
            </Button>
            <Button onClick={handleDeleteConfirm} color="error" autoFocus>
              حذف
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
  );
}

export default GalleryUpdate;
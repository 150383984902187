import React, { useState, useContext } from "react";
import { json, Link, Navigate } from "react-router-dom";
import {
  Snackbar,
  Alert,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Paper,
  Stack,
  InputLabel,
} from "@mui/material";



import { AuthContext } from "../../utils/AuthContext";
import PanelSidebar from "../../Components/SideBar/sideBar";

const JobPanel = () => {
  const { loginUser } = useContext(AuthContext);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [skills, setSkills] = useState([{ text: "" }]);
  const [gender, setGender] = useState("آقا/خانم");
  const [education, setEducation] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [file, setFile] = useState(null);

  const handleAddSkills = () => {
    setSkills([...skills, { text: "" }]);
  };

  const handleDeleteSkills = (index) => {
    const updatedSkillss = skills.filter((_, i) => i !== index);
    setSkills(updatedSkillss);
  };

  const handleSkillsChanges = (index, field, value) => {
    const newSkillss = [...skills];
    newSkillss[index][field] = value;
    setSkills(newSkillss);
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const addJob = async (e) => {
    e.preventDefault();

    if (!title || !file || !education || !skills) {
      setAlertMessage("لطفا تمامی فیلدهای ضروری را پر کنید.");
      setAlertSeverity("error");
      setAlertOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("file", file); // Attach file as `logo`
    formData.append("description", description);
    formData.append("skills", JSON.stringify(skills));

    // Convert skills to an array
    formData.append("gender", gender);
    formData.append("education", education);

    try {
      const response = await fetch("https://api.asiamehr.com/api/v1/job", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loginUser.token}`,
        },
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        setAlertMessage("شغل با موفقیت اضافه شد!");
        setAlertSeverity("success");
        setAlertOpen(true);
        setTitle("");
        setDescription("");
        // setSkills('');
        setSkills([{ text: "" }]);
        setGender("آقا/خانم");
        setEducation("");
        setFile(null);

      } else {
        setAlertMessage("افزودن شغل ناموفق بود.");
        setAlertSeverity("error");
        setAlertOpen(true);
        console.log(data.message);
      }
    } catch (error) {
      setAlertMessage("خطا در ارتباط با سرور.");
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  if (!loginUser?.token) {
    return <Navigate to="/login" />;
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background:
          "linear-gradient(180deg, rgba(0,31,63,1) 0%, rgba(0,81,165,1) 100%)",
        direction: "rtl",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="flex-end"
      >
        <Stack
          sx={{
            width: { xs: "100%", md: "80%" },
            minHeight: "100vh",
            marginTop: 10,
            marginRight: { xs: 0, md: 35 },
            padding: { xs: 2, md: 0 },
          }}
        >
          <Box
            sx={{
              padding: "50px 5%",
              borderRadius: "20px",
              marginTop: "50px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
            }}
          >
            <Typography
              variant={{ xs: "h5", md: "h4" }}
              my="20px"
              color="gainsboro"
              align="center"
            >
              افزودن شغل جدید
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputLabel sx={{ color: "#fff", margin: "8px" }}>
                  عنوان شغل
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    "& input": {
                      textAlign: "right !important",
                      fontSize: "16px",
                    },
                    direction: "rtl",
                    boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { border: "none" },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <InputLabel sx={{ color: "#fff", margin: "8px" }}>
                  مهارت‌ها
                </InputLabel>
                {skills.map((skillsItem, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      mt: 2,
                    }}
                  >
                    <TextField
                      fullWidth
                      value={skillsItem.text}
                      onChange={(e) =>
                        handleSkillsChanges(index, "text", e.target.value)
                      }
                      required
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        "& input": {
                          textAlign: "right !important",
                          fontSize: "16px",
                        },
                        direction: "rtl",
                        boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { border: "none" },
                        },
                      }}
                    />
                    <Button
                      variant="outlined"
                      color="error"
                      sx={{
                        padding: "10px",
                        borderRadius: "10px",
                        fontWeight: "bold",
                        "&:hover": {
                          backgroundColor: "#27632a",
                        },
                      }}
                      onClick={() => handleDeleteSkills(index)}
                    >
                      حذف
                    </Button>
                  </Box>
                ))}
                <Grid xs={12} md={4}>
                  <Button
                    variant="contained"
                    sx={{
                      marginTop: 2,
                      width: "100%",
                      padding: "10px",
                      borderRadius: "10px",
                      backgroundColor: "#2e7d32",
                      color: "white",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#27632a",
                      },
                    }}
                    onClick={handleAddSkills}
                  >
                    افزودن مهارت
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                <InputLabel sx={{ color: "#fff", margin: "8px" }}>
                  جنسیت
                </InputLabel>
                <Select
                  fullWidth
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  displayEmpty
                  required
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    direction: "rtl",
                    boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { border: "none" },
                    },
                  }}
                >
                  <MenuItem value="آقا">آقا</MenuItem>
                  <MenuItem value="خانم">خانم</MenuItem>
                  <MenuItem value="آقا/خانم">آقا/خانم</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} md={12}>
                <InputLabel sx={{ color: "#fff", margin: "8px" }}>
                  مدرک تحصیلی
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={education}
                  onChange={(e) => setEducation(e.target.value)}
                  required
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    direction: "rtl",
                    boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { border: "none" },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <InputLabel sx={{ color: "#fff", margin: "8px" }}>
                  توضیحات شغل (اختیاری)
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiline
                  rows={4}
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    direction: "rtl",
                    boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { border: "none" },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Button variant="contained" component="label" fullWidth>
                  انتخاب لوگو
                  <input
                    type="file"
                    hidden
                    onChange={handleFileChange}
                    required
                  />
                </Button>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              onClick={addJob}
              fullWidth
              sx={{ padding: "12px", fontSize: "16px" }}
            >
              اضافه کردن شغل
            </Button>
          </Box>
          <Link to='delete'>حذف کردن محتوا</Link>
        </Stack>
        <PanelSidebar /><Link
          to="delete"
          style={{
            textDecoration: "none",
            color: "white",
          }}
        >
          حذف کردن محتوا
        </Link>


      </Stack>

      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default JobPanel;

import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Box, Grid, Typography, Button, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { AuthContext } from "../../utils/AuthContext";
import PanelSidebar from "../../Components/SideBar/sideBar";
import { Navigate } from 'react-router-dom';

function ArticleUpdate() {
  const [articleData, setArticleData] = useState([]);
  const { id } = useParams();
  const { loginUser } = useContext(AuthContext);
  const [open, setOpen] = useState(false); 
  const [currentArticleId, setCurrentArticleId] = useState(null); 

  useEffect(() => {
    fetch(`https://api.asiamehr.com/api/v1/article`)
      .then((res) => res.json())
      .then((data) => setArticleData(data.data.articles))
      .catch((err) => console.log(err));
  }, []);

  const deleteItem = () => {
    fetch(`https://api.asiamehr.com/api/v1/article/${currentArticleId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loginUser.token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setArticleData(articleData.filter(article => article._id !== currentArticleId));
        setOpen(false); 
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteClick = (id) => {
    setCurrentArticleId(id); 
    setOpen(true); 
  };

  const handleCloseDialog = () => {
    setOpen(false); 
  };

  return (
    !loginUser?.token ? <Navigate to="/login" /> :
      <Box display="flex" flexDirection="column" alignItems="center" p={3} sx={
        {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            padding: "20px",
            direction: "rtl",
        }
      }>
        <Typography variant="h4" gutterBottom>
          حذف کردن اطلاعات
        </Typography>
        <PanelSidebar />
        <Grid container spacing={3} justifyContent="center">
          {articleData?.map(article => (
            <Grid item xs={12} sm={6} md={4} key={article._id}>
              <Card>
                <CardMedia
                  component="img"
                  height="200"
                  image={`https://api.asiamehr.com${article.image}`}
                  alt={article.title}
                />
                <CardContent>
                  <Typography variant="h6">{article.title}</Typography>
                  <Typography variant="body2" color="text.secondary">{article.description}</Typography>
                </CardContent>
                <Button 
                  variant="contained" 
                  color="secondary" 
                  fullWidth 
                  onClick={() => handleDeleteClick(article._id)}>
                  حذف
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Confirmation Dialog */}
        <Dialog open={open} onClose={handleCloseDialog}>
          <DialogTitle>آیا مطمئن هستید؟</DialogTitle>
          <DialogContent>
            <Typography>آیا از حذف این خبر اطمینان دارید؟</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">لغو</Button>
            <Button onClick={deleteItem} color="secondary">حذف</Button>
          </DialogActions>
        </Dialog>
      </Box>
  );
}

export default ArticleUpdate;

import React, { useContext, useState } from 'react';
import { Button, TextField, Snackbar, Alert, Box, Typography, Stack } from '@mui/material';
import { AuthContext } from '../../utils/AuthContext';

const MyForgotPassword = () => {
  const { loginUser, setLoginUser } = useContext(AuthContext);
  const [phone, setPhone] = useState('');
  const [personalCode, setPersonalCode] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1); // مراحل: 1 = ارسال کد، 2 = تایید کد، 3 = تغییر رمز
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState('success'); // 'success' or 'error'
  const [successLogin, setSuccessLogin] = useState(false); ///new
  const sendCode = async () => {
    try {
      const response = await fetch('https://api.asiamehr.com/api/v1/password-reset/forgot-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone }),
      });
      const data = await response.json();
      setMessage(data.message);
      if (response.ok) {
        setSeverity('success');  
        console.log(data)
        setLoginUser({ phone:data?.data?.user.phone});
        
        setStep(2);
      } else {
        setSeverity('error');
      }
    } catch (error) {
      setMessage('مشکلی پیش آمد.');
      setSeverity('error');
    }
    setOpenSnackbar(true);
  };

  const verifyCode = async () => {
    try {
      const response = await fetch('https://api.asiamehr.com/api/v1/password-reset/verify-code', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone, code }),
      });
      const data = await response.json();
      setMessage(data.message);
      if (response.ok) setStep(3);
    } catch (error) {
      setMessage('مشکلی پیش آمد.');
      setSeverity('error');
    }
    setOpenSnackbar(true);
  };

  const changePassword = async () => {
    try {
      const response = await fetch('https://api.asiamehr.com/api/v1/password-reset/change-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone, newPassword, personalCode }),
      });
      const data = await response.json();
      setMessage(data.message);
      if (response.ok) {
        setSeverity('success');
        setStep(1);
      } else {
        setSeverity('error');
      }
    } catch (error) {
      setMessage('مشکلی پیش آمد.');
      setSeverity('error');
    }
    setOpenSnackbar(true);
  };

  return (
    <Box
    sx={{
      backgroundColor: "#306352",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      padding: "16px",
    }}
  >
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        padding: "24px",
        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
        width: "100%",
        maxWidth: "400px",
        textAlign: "center",
      }}
    >
     
      <Stack sx={{ width: '100%', maxWidth: 450, padding: 3, backgroundColor: '#ffffff', borderRadius: 2, boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
        <Typography variant="h4" sx={{ marginBottom: 2, textAlign: 'center', fontWeight: 600 }}>فراموشی رمز عبور</Typography>
        
        {step === 1 && (
          <>
            <TextField
              label="شماره تلفن خود را وارد کنید"
              variant="outlined"
              fullWidth
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#f9f9f9',
                  borderRadius: '8px',
                  height: '56px', // Consistent input height
                  '& fieldset': {
                    borderColor: '#ccc',
                  },
                  '&:hover fieldset': {
                    borderColor: '#aaa', // Border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1890ff', // Border color when focused
                  },
                },
                '& .MuiInputLabel-root': {
                  top: '50%',
                  transform: 'translateY(-30%)', // Center the placeholder
                },
                '& .MuiInputLabel-shrink': {
                  top: '0', // Adjust position when the label shrinks
                  transform: 'translateY(0)',
                },
              }}
            />
            <Button
              variant="contained"
              fullWidth
              onClick={sendCode}
              sx={{
                marginTop: 2,
                backgroundColor: '#bd9b19',
                color: '#fff',
                '&:hover': { backgroundColor: '#ffcc00' },
              }}
            >
              ارسال کد
            </Button>
          </>
        )}
        
        {step === 2 && (
          <>
            <TextField
              label="کد تایید را وارد کنید"
              variant="outlined"
              fullWidth
              value={code}
              onChange={(e) => setCode(e.target.value)}
              margin="normal"
              sx={{ '& .MuiInputBase-input': { textAlign: 'center' } }}
            />
            <Button
              variant="contained"
              fullWidth
              onClick={verifyCode}
              sx={{
                marginTop: 2,
                backgroundColor: '#bd9b19',
                color: '#fff',
                '&:hover': { backgroundColor: '#ffcc00' },
              }}
            >
              تایید کد
            </Button>
          </>
        )}

        {step === 3 && (
          <>
            <TextField
              label="رمز عبور جدید"
              variant="outlined"
              fullWidth
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              margin="normal"
              sx={{ '& .MuiInputBase-input': { textAlign: 'center' } }}
            />
            <Button
              variant="contained"
              fullWidth
              onClick={changePassword}
              sx={{
                marginTop: 2,
                backgroundColor: '#bd9b19',
                color: '#fff',
                '&:hover': { backgroundColor: '#ffcc00' },
              }}
            >
              تغییر رمز
            </Button>
          </>
        )}

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity={severity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>
      </Box>
    </Box>
  );
};

export default MyForgotPassword;

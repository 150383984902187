import {
  Box,
  Input,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Modal,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  ListItemText,
  Grid,
  Autocomplete,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { AuthContext } from "../../../utils/AuthContext";
import notify from "../../../utils/hotToast";
import ReactQuill from "react-quill";
import ShadowDomComponent from "../../../Components/ShadowDom";
import CircularProgress from "@mui/material/CircularProgress";
import "react-quill/dist/quill.snow.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import PanelSidebar from "../../../Components/SideBar/sideBar";
export default function CreateRequest() {
  const navigate = useNavigate();

  const { loginUser } = useContext(AuthContext);
  const { token = "" } = loginUser;
  const isMobile = useMediaQuery("(max-width:900px)");
  const [recipient, setRecipient] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ size: ["small", false, "large", "huge"] }],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }], // گزینه برای راست‌چین کردن
    ],
    clipboard: {
      // توصیه می‌شود برای متن‌های RTL پشتیبانی بهتری اضافه کنید
      matchVisual: false,
    },
  };
  useEffect(() => {
    setLoading(true);
    fetch(`https://api.asiamehr.com/api/v1/process?filters[isActive]=true`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setOptions(data?.data?.process || []);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [searchTerm, token]);

  const formats = [
    "header",
    "font",
    "size",
    "align",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "direction",
  ];
  const handleSubmit = async (event) => {
    setSendLoading(true);
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("processId", recipient);
      formData.append("content", message);
      if (file) {
        formData.append("file", file);
      }
      const res = await fetch(`https://api.asiamehr.com/api/v1/request`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await res.json();
      if (data.success) {
        navigate("/panel/request/myRequest");
        notify(data?.message, "success");
      } else {
        notify("اشکال در ثبت درخواست");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setFile(null);
      setMessage("");
      setRecipient("");
      setSendLoading(false);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  return (
    <Box
      sx={{
        minHeight: "100vh",
        background:
          "linear-gradient(180deg, rgba(0,31,63,1) 0%, rgba(0,81,165,1) 100%)",
      }}
    >
      <Stack
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="flex-end"
      >
        <PanelSidebar />
        <Stack
          sx={{
            width: isMobile ? "100%" : "80%",
            minHeight: "100vh",
            marginTop: 10,
            marginRight: isMobile ? 0 : 35,
            padding: isMobile ? 2 : 0,
          }}
        >
          <Box
            component="form"
            onSubmit={handleSubmit}
            dir="rtl"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
              padding: "50px 5%",
              borderRadius: "20px",
              marginTop: "50px",
            }}
          >
            <Typography variant="h4" my={"20px"} color={"gainsboro"}>
              ارسال درخواست
            </Typography>

            <Stack
              flexDirection={isMobile ? "column" : "row"}
              flexWrap={"wrap"}
              gap={2}
              width={"100%"}
            >
              <Autocomplete
                size="small"
                options={options}
                getOptionLabel={(option) => option?.name}
                loading={loading}
                onInputChange={(event, newInputValue) => {
                  setSearchTerm(newInputValue);
                }}
                onChange={(event, newValue) => {
                  setRecipient(newValue ? newValue._id : "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="نوع فرآیند"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{ direction: "rtl", textAlign: "right" }}
                  >
                    {option?.name}
                  </li>
                )}
                ListboxProps={{
                  style: {
                    maxHeight: "350px",
                    overflowY: "auto",
                  },
                }}
                sx={{
                  width: !isMobile ? "300px" : "100%",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  "& input": {
                    textAlign: "right !important",
                    fontSize: "16px",
                  },
                  textAlign: "right !important",
                  direction: "rtl",
                  border: "none",
                  boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiAutocomplete-listbox": {
                    maxHeight: "350px",
                    overflowY: "auto",
                  },
                }}
              />
            </Stack>

            <Box
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                padding: "10px",
                boxShadow: "0 0 10px rgba(0,0,0,.3)",
                minHeight: "300px",
              }}
            >
              <ShadowDomComponent>
                <ReactQuill
                  value={message}
                  onChange={setMessage}
                  modules={modules}
                  formats={formats}
                  theme="snow"
                  placeholder="درخواست خود را بنویسید ...."
                />
              </ShadowDomComponent>
            </Box>
            <Box sx={{ textAlign: "left !important" }}>
              {!file ? (
                <Button
                  variant="contained"
                  component="label"
                  sx={{ marginLeft: "20px" }}
                >
                  انتخاب فایل پیوست
                  <input type="file" hidden onChange={handleFileChange} />
                </Button>
              ) : (
                <Button
                  variant="contained"
                  component="label"
                  sx={{ marginLeft: "20px" }}
                  color="error"
                  onClick={() => setFile(null)}
                >
                  حذف پیوست
                </Button>
              )}
              <LoadingButton
                loading={sendLoading}
                type="submit"
                variant="contained"
                color="success"
              >
                ارسال
              </LoadingButton>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import SearchIcon from '@mui/icons-material/Search';
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import GroupIcon from "@mui/icons-material/Group";
import SystemUpdateIcon from "@mui/icons-material/SystemUpdate";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import BurstModeRoundedIcon from "@mui/icons-material/BurstModeRounded";
import CollectionsRoundedIcon from "@mui/icons-material/CollectionsRounded";
import NewspaperRoundedIcon from "@mui/icons-material/NewspaperRounded";
import ForwardToInboxRoundedIcon from "@mui/icons-material/ForwardToInboxRounded";
import Typography from "@mui/material/Typography";
import ListAltIcon from '@mui/icons-material/ListAlt';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import IosShareIcon from '@mui/icons-material/IosShare';
import SpeedTwoToneIcon from "@mui/icons-material/SpeedTwoTone";
import {
  ListItemButton,
  Chip,
  Stack,
  Button,
  Avatar,
  Collapse,
  IconButton,
} from "@mui/material";
import LocalMallTwoToneIcon from "@mui/icons-material/LocalMallTwoTone";
import LocalPoliceTwoToneIcon from "@mui/icons-material/LocalPoliceTwoTone";
import CategoryTwoToneIcon from "@mui/icons-material/CategoryTwoTone";
import Inventory2TwoToneIcon from "@mui/icons-material/Inventory2TwoTone";
import BallotTwoToneIcon from "@mui/icons-material/BallotTwoTone";
import QueueTwoToneIcon from "@mui/icons-material/QueueTwoTone";
import Face2TwoToneIcon from "@mui/icons-material/Face2TwoTone";
import BadgeTwoToneIcon from "@mui/icons-material/BadgeTwoTone";
import GroupAddTwoToneIcon from "@mui/icons-material/GroupAddTwoTone";
import Diversity1TwoToneIcon from "@mui/icons-material/Diversity1TwoTone";
import { Person2Rounded } from "@mui/icons-material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { AuthContext } from "../../utils/AuthContext";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Navigate } from "react-router-dom";
import RecentActorsRoundedIcon from "@mui/icons-material/RecentActorsRounded";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import OutboxIcon from "@mui/icons-material/Outbox";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import AddIcon from "@mui/icons-material/Add";
import ArchiveIcon from "@mui/icons-material/Archive";
import EmailIcon from "@mui/icons-material/Email";

const drawerWidth = 240;

const Employees = ({ children, location }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState({
    users: false,
    update: false,
    wage: false,
    inbox: false,
    externalLetter: false,
    internalLetter: false,
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openMenu, setOpenMenu] = useState(false);
  const { loginUser, setLoginUser } = useContext(AuthContext);
  const handleDrawerOpen = () => {
    setOpenMenu(true);
  };

  const handleDrawerClose = () => {
    setOpenMenu(false);
  };

  return (
    <div style={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "#818589",
        }}
      >
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="end"
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" noWrap>
            داشبورد کارمند
          </Typography>
          
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        anchor="right"
        open={isMobile ? openMenu : true}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
          ...(isMobile && {
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }),
        }}
      >
        <Toolbar />
        <List>
          <Stack justifyContent="center" alignItems="center" p={2} gap={2}>
            <Typography component="p">
              سلام {loginUser?.fullName ? loginUser.fullName : loginUser?.role}
            </Typography>
            <Chip
              icon={<LocalPoliceTwoToneIcon />}
              label={loginUser?.role}
              color="warning"
              variant="filled"
              size="medium"
              sx={{
                "& .MuiChip-label": { paddingRight: "0" },
                "& .MuiChip-icon": { marginRight: "6px" },
              }}
            />
          </Stack>
          <ListItemButton
            component={Link}
            to="/employee"
            selected={location?.pathname === "/employee"}
          >
            <ListItemIcon>
              <SpeedTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="داشبورد" />
          </ListItemButton>

         
        

          <ListItemButton component={Link} to="/panel/profile">
                  <ListItemIcon>
                    <RecentActorsRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="ویرایش صفحه کاربری " />
                </ListItemButton>

       

          
          <ListItemButton
            onClick={() =>
              setOpen({ ...open, externalLetter: !open.externalLetter })
            }
          >
            <ListItemIcon>
              <MarkunreadMailboxIcon />
            </ListItemIcon>
            <ListItemText primary="نامه های برون سازمانی" />
            {open?.externalLetter ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open?.externalLetter} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton component={Link} to="/panel/external-letter/add">
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary="ایجاد نامه" />
              </ListItemButton>
              {loginUser?.role == "admin" || loginUser?.role == "superAdmin" ? (
                <>
                  <ListItemButton
                    component={Link}
                    to="/panel/external-letter/all"
                  >
                    <ListItemIcon>
                      <AllInboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="همه نامه ها" />
                  </ListItemButton>
                  <ListItemButton
                    component={Link}
                    to="/panel/external-letter/receive"
                  >
                    <ListItemIcon>
                      <MarkunreadMailboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="نامه های دریافتی" />
                  </ListItemButton>
                </>
              ) : (
                ""
              )}
              <ListItemButton component={Link} to="/panel/external-letter/sent">
                <ListItemIcon>
                  <OutboxIcon />
                </ListItemIcon>
                <ListItemText primary="نامه های ارسالی" />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to="/panel/external-letter/terminated"
              >
                <ListItemIcon>
                  <AssignmentTurnedInIcon />
                </ListItemIcon>
                <ListItemText primary="نامه های خاتمه یافته" />
              </ListItemButton>
              {loginUser?.role == "admin" ||
              loginUser?.role == "superAdmin" ||
              loginUser?.role == "archive" ? (
                <ListItemButton
                  component={Link}
                  to="/panel/external-letter/archive"
                >
                  <ListItemIcon>
                    <ArchiveIcon />
                  </ListItemIcon>
                  <ListItemText primary="بایگانی" />
                </ListItemButton>
              ) : (
                ""
              )}
               <ListItemButton
                component={Link}
                to="/panel/external-letter/search"
              >
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary="جستجو" />
              </ListItemButton>
              
            </List>
          </Collapse>
          <ListItemButton
            onClick={() =>
              setOpen({ ...open, internalLetter: !open.internalLetter })
            }
          >
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="نامه های درون سازمانی" />
            {open?.internalLetter ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open?.internalLetter} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton component={Link} to="/panel/internal-Letter/add">
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary="ایجاد نامه" />
              </ListItemButton>
              {loginUser?.role == "admin" || loginUser?.role == "superAdmin" ? (
                <>
                  <ListItemButton
                    component={Link}
                    to="/panel/internal-Letter/all"
                  >
                    <ListItemIcon>
                      <AllInboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="همه نامه ها" />
                  </ListItemButton>
                </>
              ) : (
                ""
              )}
              <ListItemButton
                component={Link}
                to="/panel/internal-Letter/receive"
              >
                <ListItemIcon>
                  <MarkEmailReadIcon />
                </ListItemIcon>
                <ListItemText primary="نامه های دریافتی" />
              </ListItemButton>
              <ListItemButton component={Link} to="/panel/internal-Letter/sent">
                <ListItemIcon>
                  <OutboxIcon />
                </ListItemIcon>
                <ListItemText primary="نامه های ارسالی" />
              </ListItemButton>
              <ListItemButton
                component={Link}
                to="/panel/internal-letter/search"
              >
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary="جستجو" />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton
              onClick={() => setOpen({ ...open, request: !open.request })}
            >
              <ListItemIcon>
                <IosShareIcon  />
              </ListItemIcon>
              <ListItemText primary="درخواست" />
              {open?.request ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open?.request} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton component={Link} to="/panel/request/create">
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary="ایجاد درخواست" />
                </ListItemButton>
                {loginUser?.role == "admin" || loginUser?.role == "superAdmin" ? (
                  <>
                    <ListItemButton
                      component={Link}
                      to="/panel/request/all"
                    >
                      <ListItemIcon>
                        <AllInboxIcon />
                      </ListItemIcon>
                      <ListItemText primary="همه درخواست ها" />
                    </ListItemButton>
                  </>
                ) : (
                  ""
                )}
                <ListItemButton component={Link} to="/panel/request/myRequest">
                  <ListItemIcon>
                    <OutboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="درخواست های من" />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="/panel/request/pending"
                >
                  <ListItemIcon>
                    <HourglassBottomIcon />
                  </ListItemIcon>
                  <ListItemText primary="درخواست های در انتظار من" />
                </ListItemButton>
                <ListItemButton component={Link} to="/panel/request/complete">
                  <ListItemIcon>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText primary="درخواست های خاتمه یافته" />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="/panel/request/search"
                >
                  <ListItemIcon>
                    <SearchIcon />
                  </ListItemIcon>
                  <ListItemText primary="جستجو" />
                </ListItemButton>
              </List>
            </Collapse>
          <ListItemButton
            onClick={() => setOpen({ ...open, transcript: !open.transcript })}
          >
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="رونوشت ها" />
            {open?.transcript ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open?.transcript} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {loginUser?.role == "admin" || loginUser?.role == "superAdmin" ? (
                <>
                  <ListItemButton component={Link} to="/panel/transcript/all">
                    <ListItemIcon>
                      <AllInboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="همه رونوشت ها" />
                  </ListItemButton>
                </>
              ) : (
                ""
              )}
              <ListItemButton component={Link} to="/panel/transcript/receive">
                <ListItemIcon>
                  <MarkEmailReadIcon />
                </ListItemIcon>
                <ListItemText primary="رونوشت دریافتی" />
              </ListItemButton>
              <ListItemButton component={Link} to="/panel/transcript/sent">
                <ListItemIcon>
                  <OutboxIcon />
                </ListItemIcon>
                <ListItemText primary="رونوشت ارسالی" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
        <Button
          variant="outlined"
          onClick={() => {
            setLoginUser(null);
            localStorage.removeItem("loginUser");
          }}
        >
          خروج
        </Button>
      </Drawer>
      <main>
        <Toolbar />
        {children}
      </main>
    </div>
  );
};

export default Employees;

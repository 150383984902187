import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  Typography,
  CircularProgress,
  Alert,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useParams, useNavigate, Navigate } from "react-router-dom";

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AuthContext } from '../../utils/AuthContext';
import PanelSidebar from '../../Components/SideBar/sideBar';
const EditUserProfile = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState({
  address:"",
  email:"",
  password:"",
  });
  const { id } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { loginUser, setLoginUser } = useContext(AuthContext);
 
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`https://api.asiamehr.com/api/v1/user/${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${loginUser.token}`,
          },
        });

        if (!response.ok) throw new Error("Failed to fetch user data");

        const data = await response.json();
        setUser(data?.data?.user);
        console.log("API Response:", data);
        setFormData({
        
          email: data?.data?.user.email,
      address: data?.data?.user.address,
      password: "",
        });
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (id) fetchUser();
  }, [id, loginUser.token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      const response = await fetch(`https://api.asiamehr.com/api/v1/user/profile/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
           Authorization: `Bearer ${loginUser.token}`,
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      console.log("API Response:", data);
      if (!response.ok) {
        throw new Error(data.message || "Failed to update profile");
      }
      setLoginUser({
        ...loginUser,
        email: formData.email,
        address: formData.address,
      });
      setSuccess("پروفایل اپدیت شد");
    //   setTimeout(() => navigate(`/user/${id}`), 1500);
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!user) return <Alert severity="warning">User not found</Alert>;
  if (!loginUser?.token) return <Navigate to="/login" />;
  return (
    <Box  sx={{  display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center', 
        width:"100%",
        height:"100vh",
        background:
        "linear-gradient(180deg, rgba(0,31,63,1) 0%, rgba(0,81,165,1) 100%)",
        
        }} >
            <PanelSidebar/>
     <Paper elevation={3} sx={{ p: 3, borderRadius: 2, maxWidth: 600, mx: "auto" }}>
      <Typography variant="h5" gutterBottom>
        ویرایش پروفایل
      </Typography>

      

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="ادرس"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
             
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="ایمیل"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              
            />
          </Grid>
          <Grid item xs={12}>
          <TextField
            label="رمز عبور"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={formData.password}
            onChange={handleInputChange}
            fullWidth
            placeholder="حداقل 8 کاراکتر"
            inputProps={{ minLength: 8 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          </Grid>

        

        

          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                ذخیره تغییرات
              </Button>
              {/* <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate(`/user/${id}`)}
              >
                انصراف
              </Button> */}
            </Box>
          </Grid>
        </Grid>
      </form>
    </Paper>
    {success && <Alert severity="success">{success}</Alert>}
    </Box>
   
  );
};

export default EditUserProfile;
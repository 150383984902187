import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Container,
  TextField,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Paper,
  Typography,
  useMediaQuery,
  Stack,
  InputAdornment,
} from "@mui/material";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { Link, useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AuthContext } from "../../../utils/AuthContext";
import Loader from "../../../Components/Loader";
import notify from "../../../utils/hotToast";
import PanelSidebar from "../../../Components/SideBar/sideBar";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export const TableItems = ({ id, processNumber, isActive, processName, steps }) => {
  const [open, setOpen] = React.useState(false);
  const [active, setActive] = useState(isActive);
  const { loginUser } = useContext(AuthContext);
  const { token = "" } = loginUser || {};

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (confirm) => {
    setOpen(false);
    if (confirm) {
      updateProcess();
    }
  };

  const updateProcess = () => {
    fetch(`https://api.asiamehr.com/api/v1/process/${id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: processName,
        stepCount: +processNumber,
        steps: steps,
        isActive: !active,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          setActive(!active);
          notify("فرایند با موفقیت به روزرسانی شد", "success");
        } else {
          notify("خطا در به روزرسانی فرایند", "error");
        }
      });
  };

  return (
    <>
      <TableRow>
        <TableCell align="right" sx={{ gap: 2 }}>
          <Link
            style={{ color: "black", margin: "0 10px" }}
            to={`/panel/process/${id}`}
          >
            <RemoveRedEyeIcon />
          </Link>
        </TableCell>
        <TableCell align="right">
          <Android12Switch
            checked={active}
            onChange={handleClickOpen}
          />
          {!active ? "غیرفعال" : "فعال"}
        </TableCell>
        <TableCell align="right">{processNumber}</TableCell>
        <TableCell align="right">{processName}</TableCell>
      </TableRow>

      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`آیا از ${active ? "غیرفعال سازی" : "فعال سازی"} فرآیند اطمینان دارید؟`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* {`آیا از ${active ? "غیرفعال سازی" : "فعال سازی"} فرآیند اطمینان دارید؟`} */}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "row-reverse" }}>
          <Button onClick={() => handleClose(false)}>خیر</Button>
          <Button sx={{ color: "red" }} onClick={() => handleClose(true)} autoFocus>
            بله
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default function UpdateProcess() {
  const isMobile = useMediaQuery("(max-width:900px)");
  const { loginUser } = useContext(AuthContext);
  const { token = "" } = loginUser;
  const [letters, setLetters] = useState(null);
  const [sort, setSort] = useState("-createdAt");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [search, setSearch] = useState("");

  useEffect(() => {
    (async () => {
      try {
        let query = `?&sort=${sort}&page=${page}&limit=${10}`;
        if (search) {
          query += `&search[name]=${search}`;
        }
        const res = await fetch(
          `https://api.asiamehr.com/api/v1/process${query}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (data.success) {
          setLetters(data?.data?.process);
          setCount(Math.ceil(data?.data?.count / 10));
        } else {
          alert("اشکال در برقراری اتصال");
        }
      } catch (err) {
        alert("اشکال در برقراری اتصال");
      }
    })();
  }, [token, sort, page , search]);

  const items = letters?.map((e, index) => (
    <TableItems
      key={index}
      id={e._id}
      processName={e?.name}
      processNumber={e?.stepCount}
      isActive={e?.isActive}
      steps={e?.steps}
    />
  ));

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          background:
            "linear-gradient(180deg, rgba(0,31,63,1) 0%, rgba(0,81,165,1) 100%)",
        }}
      >
        <Stack
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="flex-end"
        >
          <PanelSidebar />
          <Stack
            sx={{
              overflowX: "auto",
              width: isMobile ? "100%" : "80%",
              minHeight: "100vh",
              marginTop: 10,
              marginRight: isMobile ? 0 : 35,
              padding: isMobile ? 2 : 0,
            }}
          >
            {letters ? (
              <>
                <CacheProvider value={cacheRtl}>
                  <Stack
                    flexDirection={"row"}
                    pt={"20px"}
                    sx={{ direction: "rtl !important" }}
                  >
                    <Stack
                      dir="rtl"
                      width={"100%"}
                      justifyContent={"space-between"}
                      flexDirection={isMobile ? "column" : "row"}
                      alignItems={"center"}
                      gap={2}
                    >
                      {/* <Box
                        sx={{
                          position: "relative",
                          width: isMobile ? "100%" : "250px",
                        }}
                      >
                        <TextField
                          placeholder="جستجو"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            py: "3.5px",
                            backgroundColor: "white",
                            boxShadow: "0px 0px 10px 2px rgba(255,255,255,.5)",
                            borderRadius: "15px",
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none",
                              },
                              "&:hover fieldset": {
                                border: "none",
                              },
                              "&.Mui-focused fieldset": {
                                border: "none",
                              },
                            },
                          }}
                          dir="rtl"
                        />
                      </Box> */}

                      <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        onChange={(e) => setSort(e.target.value)}
                        placeholder="مرتب سازی بر اساس"
                        sx={{
                          width: isMobile ? "100%" : "270px",
                          borderRadius: "15px",
                          backgroundColor: "white",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                            "&:hover fieldset": {
                              border: "none",
                            },
                            "&.Mui-focused fieldset": {
                              border: "none",
                            },
                          },
                        }}
                        defaultValue={"s"}
                      >
                        <MenuItem dir="rtl" value="s">
                          مرتب سازی بر اساس
                        </MenuItem>
                        <MenuItem dir="rtl" value="-createdAt">
                          جدید ترین فرآیندها
                        </MenuItem>
                        <MenuItem dir="rtl" value="createdAt">
                          قدیمی ترین فرآیندها
                        </MenuItem>
                      </TextField>
                    </Stack>
                  </Stack>

                  <TableContainer
                    sx={{
                      marginTop: "20px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                      overflowX: "auto",
                    }}
                    component={Paper}
                  >
                    <Table
                      sx={{
                        direction: "rtl",
                        minWidth: "800px",
                        borderCollapse: "collapse",
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            عملیات
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            وضعیت
                          </TableCell>

                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            تعداد مراحل
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            نام فرآیند
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items && items.length > 0 ? (
                          items
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={6}
                              align="center"
                              sx={{
                                padding: "20px",
                                fontStyle: "italic",
                                color: "#888",
                              }}
                            >
                              فرآیندی موجود نیست
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Pagination
                    siblingCount={0}
                    boundaryCount={0}
                    defaultPage={1}
                    count={count}
                    page={page}
                    onChange={(e, value) => setPage(value)}
                    color="primary"
                    sx={{
                      marginTop: 3,
                      marginBottom: 3,
                      display: "flex",
                      "& .MuiPaginationItem-root": {
                        bgcolor: "white",
                        borderRadius: 2,
                        boxShadow: "0 1px 5px rgba(0, 0, 0, 0.2)",
                        "&:hover": {
                          bgcolor: "grey.200",
                        },
                      },
                      "& .Mui-selected": {
                        backgroundColor: "primary.main",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "primary.dark",
                        },
                      },
                    }}
                  />
                </CacheProvider>
              </>
            ) : (
              <Loader />
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
}

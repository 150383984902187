import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  TextField,
  Box,
} from "@mui/material";


// import "./panel.css";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../utils/AuthContext";
import PanelSidebar from "../../Components/SideBar/sideBar";

export default function JobApplies() {
  const { loginUser } = useContext(AuthContext);
  const [userRows, setUserRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [updateFields, setUpdateFields] = useState({
    fullName: "",
    lastName: "",
    fatherName: "",
    email: "",
    phone: "",
    dateOfBirth: "",
    emergencyPhone: "",
    postalCode: "",
    nationalId: "",
    address: "",
    birthPlace: "",
    healthStatus: "",
    disabilityStatus: [],
    surgeryHistory: "No Surgery",
    city: "",
    gender: "",
    maritalStatus: "",
    cours: [
      {
        coursTitle: "",
        duration: "",
        location: "",
        date: "",
        organizer: "",
        skillLevel: "",
      },
    ],
    educationDetails: [
      {
        degreeLevel: "",
        major: "",
        specialization: "",
        university: "",
        yearOfAdmission: null,
        yearOfGraduation: null,
        gpa: null,
        thesisOrInternship: "",
        lastDegree: "",
        fieldOfStudy: "",
        jobRequest: "",
      },
    ],
    workExperience: [
      {
        companyName: "",
        jobTitle: "",
        duration: "",
        reasonForLeaving: "",
        lastSalary: null,
      },
    ],
    howDidYouGetToKnow: "",
    doFriendsWorkHere: "",
    haveWorkedBefore: "",
    additionalNotes: "",
    insuranceStatus: "",
    profilePicture: "",
    resumeOrDocuments: "",
  });

  const [snack, setSnack] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (loginUser?.token) {
      fetch("https://api.asiamehr.com/api/v1/jobapp", {
        method: "GET",
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${loginUser?.token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            setUserRows(data.data);
            console.log(data.data);
          } else {
            console.error("Failed to fetch users:", data.message);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [loginUser?.token]);

  const handleUpdate = (user) => {
    setSelectedUser(user);
    setUpdateFields({
      fullName: "",
      lastName: "",
      fatherName: "",
      email: "",
      phone: "",
      dateOfBirth: "",
      emergencyPhone: "",
      postalCode: "",
      nationalId: "",
      address: "",
      birthPlace: "",
      healthStatus: "",
      disabilityStatus: [],
      surgeryHistory: "No Surgery",
      city: "",
      gender: "",
      maritalStatus: "",
      cours: [
        {
          coursTitle: "",
          duration: "",
          location: "",
          date: "",
          organizer: "",
          skillLevel: "",
        },
      ],
      educationDetails: [
        {
          degreeLevel: "",
          major: "",
          specialization: "",
          university: "",
          yearOfAdmission: null,
          yearOfGraduation: null,
          gpa: null,
          thesisOrInternship: "",
          lastDegree: "",
          fieldOfStudy: "",
          jobRequest: "",
        },
      ],
      workExperience: [
        {
          companyName: "",
          jobTitle: "",
          duration: "",
          reasonForLeaving: "",
          lastSalary: null,
        },
      ],
      howDidYouGetToKnow: "",
      doFriendsWorkHere: "",
      haveWorkedBefore: "",
      additionalNotes: "",
      insuranceStatus: "",
      profilePicture: "",
      resumeOrDocuments: "",
    });

    setOpenDialog(true);
  };

  const openDeleteDialog = (userId) => {
    setSelectedUserId(userId);
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
    setSelectedUserId(null);
  };

  const handleConfirmDelete = async () => {
    if (!selectedUserId) return;

    try {
      const response = await fetch(
        `https://api.asiamehr.com/api/v1/jobapp/${selectedUserId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${loginUser?.token}`,
          },
        }
      );

      if (response.ok) {
        setUserRows(userRows.filter((user) => user._id !== selectedUserId));
        setSnack({
          open: true,
          message: "رزومه با موفقیت حذف شد",
          severity: "success",
        });
      } else {
        throw new Error("Error deleting user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      setSnack({
        open: true,
        message: "خطا در حذف رزومه",
        severity: "error",
      });
    } finally {
      setDeleteDialogOpen(false);
      setSelectedUserId(null);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateFields({ ...updateFields, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUpdateFields({ ...updateFields, profilePic: file });
  };

  const handleSignatureChange = (e) => {
    const file = e.target.files[0];
    setUpdateFields({ ...updateFields, signature: file });
  };

  if (!loginUser?.token) return <Navigate to="/login" />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: "20px",
        direction: "rtl",
      }}
    >
      <PanelSidebar />
      <Box sx={{ flex: 1, overflowY: "auto", padding: 2 }}>
        <Stack
          flexDirection="column"
          padding={2}
          spacing={3}
          sx={{ width: "100%", maxWidth: "800px" }}
        >
          {userRows &&
            userRows.map((application, index) => (
              <Card
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" }, // Responsive layout
                  alignItems: "flex-start",
                  padding: 2,
                  gap: 2,
                  boxShadow: 2, // Add shadow for depth
                  borderRadius: 2,
                }}
              >

                <CardMedia
                  component="img"
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginBottom: { xs: 2, sm: 0 },
                  }}
                  image={
                    application.profilePicture
                      ? `https://api.asiamehr.com${application.profilePicture}`
                      : "/path/to/default-image.jpg"
                  } // Use a default image if profile picture is missing
                  alt="Profile"
                />



                <CardContent sx={{ flex: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", marginBottom: 1 }}
                  >
                    {application.fullName}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>نام خانوادگی:</strong> {application.lastName}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>نام پدر:</strong> {application.fatherName}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>تاریخ تولد:</strong> {application.dateOfBirth}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>ایمیل:</strong> {application.email}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>تلفن:</strong> {application.phone}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>تلفن اضطراری:</strong> {application.emergencyPhone}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>کد پستی:</strong> {application.postalCode}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>کد ملی:</strong> {application.nationalId}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>آدرس:</strong> {application.address}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>شهر:</strong> {application.city}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>وضعیت تاهل:</strong>
                    {application.maritalStatus}
                  </Typography>

                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>سلامت وضعیت:</strong> {application.healthStatus}
                  </Typography>
                  {application.disabilityStatus && (
                    <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                      <strong>بیماری / وضعیت معلولیت:</strong>{" "}
                      {application.disabilityStatus?.join(", ")}
                    </Typography>
                  )}

                  {application.cours?.length > 0 && (
                    <div>
                      <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                        <strong>دوره‌ها:</strong>
                      </Typography>
                      {application.cours.map((course, idx) => (
                        <Typography
                          key={idx}
                          variant="body2"
                          sx={{ marginBottom: 0.5 }}
                        >
                          {`عنوان: ${course.coursTitle}, مدت: ${course.duration}, مکان: ${course.location}, تاریخ: ${course.date}, برگزارکننده: ${course.organizer}`}
                        </Typography>
                      ))}
                    </div>
                  )}
                  {application.educationDetails?.length > 0 && (
                    <div>
                      <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                        <strong>تحصیلات:</strong>
                      </Typography>
                      {application.educationDetails.map((edu, idx) => (
                        <Typography
                          key={idx}
                          variant="body2"
                          sx={{ marginBottom: 0.5 }}
                        >
                          {`مقطع: ${edu.degreeLevel}, 
      رشته: ${edu.major}, 
      دانشگاه: ${edu.university},
      معدل: ${edu.gpa},
      سال ورود: ${edu.yearOfAdmission},
      سال فارغ‌التحصیلی: ${edu.yearOfGraduation}, 
      شغل درخواستی: ${edu.jobRequest?.title || 'عنوان شغل در دسترس نیست'},
        و آخرین مدرک تحصیلی: ${edu.lastDegree}, گرایش: ${edu.specialization}`}
                        </Typography>
                      ))}

                    </div>
                  )}
                  {application.workExperience?.length > 0 && (
                    <div>
                      <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                        <strong>تجربیات کاری:</strong>
                      </Typography>
                      {application.workExperience.map((work, idx) => (
                        <Typography
                          key={idx}
                          variant="body2"
                          sx={{ marginBottom: 0.5 }}
                        >
                          {`شرکت: ${work.companyName}, عنوان شغلی: ${work.jobTitle},
                           مدت: ${work.duration}, دلیل ترک: ${work.reasonForLeaving} وآخرین حقوق: ${work.lastSalary}
                            `}
                        </Typography>
                      ))}
                    </div>
                  )}
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>تجربه همکاری قبلی:</strong>{" "}
                    {application.haveWorkedBefore}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong> نحوه اشنایی :</strong>{" "}
                    {application.howDidYouGetToKnow}
                  </Typography>

                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>محل تولد:</strong> {application.birthPlace}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>وضعیت معلولیت:</strong>{" "}
                    {application.disabilityStatus?.join(", ")}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>سوابق جراحی:</strong> {application.surgeryHistory}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>جنسیت:</strong> {application.gender}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>چطور با ما آشنا شدید:</strong>{" "}
                    {application.howDidYouGetToKnow}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>آیا دوستان شما در اینجا کار می‌کنند؟</strong>{" "}
                    {application.doFriendsWorkHere}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>آیا قبلاً در اینجا کار کرده‌اید؟</strong>{" "}
                    {application.haveWorkedBefore}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>یادداشت‌های اضافی:</strong>{" "}
                    {application.additionalNotes}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>وضعیت بیمه: </strong> {application.insuranceStatus}
                  </Typography>
                </CardContent>

                <Button
                  variant="contained"
                  color="primary"
                  sx={{ alignSelf: "flex-start", marginTop: { xs: 2, sm: 0 } }}
                  disabled={!application.resumeOrDocuments} // Disable the button if resume does not exist
                  onClick={() => {
                    if (application.resumeOrDocuments) {
                      window.open(
                        `https://api.asiamehr.com${application.resumeOrDocuments}`,
                        "_blank"
                      );
                    } else {
                      alert("رزومه‌ای موجود نیست");
                    }
                  }}
                >
                  {application.resumeOrDocuments
                    ? "دانلود رزومه"
                    : "رزومه موجود نیست"}{" "}
                  {/* Display a fallback text if resume is missing */}
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ alignSelf: "flex-start", marginTop: { xs: 2, sm: 0 } }}
                  onClick={() => openDeleteDialog(application._id)} // Trigger opening the dialog
                >
                  حذف رزومه
                </Button>

                {/* Dialog Component */}
                <Dialog open={deleteDialogOpen} onClose={handleDialogClose}>
                  <DialogTitle>حذف رزومه</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      آیا مطمئن هستید که می‌خواهید این رزومه را حذف کنید؟
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                      لغو
                    </Button>
                    <Button onClick={handleConfirmDelete} color="secondary">
                      حذف
                    </Button>
                  </DialogActions>
                </Dialog>
              </Card>
            ))}
        </Stack>
      </Box>

      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
        >
          {snack.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

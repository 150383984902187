import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import {  Navigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert
} from "@mui/material";
import { AuthContext } from "../../utils/AuthContext";
import PanelSidebar from "../../Components/SideBar/sideBar";

function JobUpdate() {
  const [jobData, setJobData] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [updatedJob, setUpdatedJob] = useState({
    title: "",
    education: "",
    gender: "",
    skills: [],
    description: "",
    logo: null,
  });
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("success");
  const { id } = useParams();
  const { loginUser } = useContext(AuthContext);

  useEffect(() => {
    fetch("https://api.asiamehr.com/api/v1/job")
      .then((res) => res.json())
      .then((data) => setJobData(data.data))
      .catch((err) => console.log(err));
  }, [id]);

  const handleDeleteConfirmation = (job) => {
    setSelectedJob(job);
    setDialogOpen(true);
  };

  const handleDelete = () => {
    fetch(`https://api.asiamehr.com/api/v1/job/${selectedJob._id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loginUser.token}`,
      },
    })
      .then((res) => res.json())
      .then(() => {
        setJobData(jobData.filter((job) => job._id !== selectedJob._id));
        setDialogOpen(false)
        setSnackMessage("شغل با موفقیت حذف شد");
        setSnackSeverity("success");
        setSnackOpen(true);
        ;
      })
      .catch((err) => console.log(err))

    setSnackMessage("Error deleting job");
    setSnackSeverity("error");
    setSnackOpen(true);
    ;
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedJob(null);
  };

  const handleEditJob = (job) => {
    setSelectedJob(job);
    setUpdatedJob({ ...job, skills: [...job.skills] });
    setEditMode(true);
  };

  const handleUpdateJob = () => {
    const formData = new FormData();
    formData.append("title", updatedJob.title);
    formData.append("education", updatedJob.education);
    formData.append("gender", updatedJob.gender);
    formData.append("description", updatedJob.description);
    formData.append("skills", JSON.stringify(updatedJob.skills));

    if (updatedJob.logo) {
      formData.append("logo", updatedJob.logo);
    }

    fetch(`https://api.asiamehr.com/api/v1/job/${selectedJob._id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${loginUser.token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        const updatedJobs = jobData.map((job) =>
          job._id === data.data._id ? data.data : job
        );
        setJobData(updatedJobs);
        setEditMode(false);
        setSnackMessage("شغل با موفقیت بروزرسانی شد ");
        setSnackSeverity("success");
        setSnackOpen(true);
      })
      .catch((err) => console.log(err))
    setSnackMessage("Error updating job");
    setSnackSeverity("error");
    setSnackOpen(true);
    ;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedJob({ ...updatedJob, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUpdatedJob({ ...updatedJob, logo: file });
    }
  };

  const handleAddSkill = () => {
    setUpdatedJob({
      ...updatedJob,
      skills: [...updatedJob.skills, { text: "" }],
    });
  };

  const handleSkillChange = (index, field, value) => {
    const updatedSkills = [...updatedJob.skills];
    updatedSkills[index][field] = value;
    setUpdatedJob({ ...updatedJob, skills: updatedSkills });
  };

  const handleRemoveSkill = (index) => {
    const updatedSkills = updatedJob.skills.filter((_, i) => i !== index);
    setUpdatedJob({ ...updatedJob, skills: updatedSkills });
  };
  const handleCloseSnackbar = () => {
    setSnackOpen(false);
  };
  if (!loginUser?.token) {
    return <Navigate to="/login" />;
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "#f8f6ed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: { xs: "20px", md: "30px 100px" },
        width: "100%",
      }}
    >
      <Stack
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", padding: 2 }}
      >
        <Stack
          sx={{
            width: { xs: "90%", md: "60%" },
            maxWidth: "900px",
            minHeight: "100vh",
            padding: { xs: 2, md: 4 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
            {editMode ? "ویرایش شغل" : "حذف کردن اطلاعات"}
          </Typography>

          {editMode ? (
            <Box sx={{ width: "100%" }}>
              <TextField
                label="عنوان شغل"
                name="title"
                value={updatedJob.title}
                onChange={handleInputChange}
                fullWidth
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="تحصیلات"
                name="education"
                value={updatedJob.education}
                onChange={handleInputChange}
                fullWidth
                sx={{ marginBottom: 2 }}
              />
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel>جنسیت</InputLabel>
                <Select
                  name="gender"
                  value={updatedJob.gender}
                  onChange={handleInputChange}
                  label="جنسیت"
                >
                  <MenuItem value="آقا/خانم">آقا/خانم</MenuItem>
                  <MenuItem value="خانم">خانم</MenuItem>
                  <MenuItem value="آقا">آقا</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="توضیحات"
                name="description"
                value={updatedJob.description}
                onChange={handleInputChange}
                fullWidth
                sx={{ marginBottom: 2 }}
              />

              {/* Add/Edit Skills */}
              <Typography variant="h6" sx={{ marginBottom: 2 }}>
                مهارت‌ها
              </Typography>
              {updatedJob.skills.map((skill, index) => (
                <Box key={index} sx={{ display: "flex", gap: 1, marginBottom: 2 }}>
                  <TextField
                    label="متن مهارت"
                    name="text"
                    value={skill.text}
                    onChange={(e) =>
                      handleSkillChange(index, "text", e.target.value)
                    }
                    fullWidth
                  />

                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => handleRemoveSkill(index)}
                  >
                    حذف
                  </Button>
                </Box>
              ))}
              <Button
                variant="outlined"
                color="primary"
                onClick={handleAddSkill}
                sx={{ marginBottom: 2 }}
              >
                افزودن مهارت
              </Button>

              {/* Image upload input */}
              <Button variant="contained" component="label" sx={{ marginBottom: 2 }}>
                انتخاب تصویر
                <input type="file" hidden onChange={handleImageChange} />
              </Button>
              {updatedJob.logo && (
                <Typography variant="body2" color="text.secondary">
                  تصویر انتخاب شده: {updatedJob.logo.name}
                </Typography>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateJob}
                fullWidth
              >
                به‌روزرسانی
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                justifyContent: "center",
              }}
            >
              {jobData?.map((job) => (
                <Card
                  key={job._id}
                  sx={{
                    width: 300,
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    boxShadow: 3,
                  }}
                >
                  <CardMedia
                    component="img"
                    alt={job.title}
                    height="140"
                    image={`https://api.asiamehr.com${job.logo}`}
                    sx={{ objectFit: "fill" }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="div" noWrap>
                      {job.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      تحصیلات: {job.education}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      جنسیت: {job.gender}
                    </Typography>

                    <Typography variant="body2" color="text.secondary" noWrap>
                      مهارت‌ها: {job.skills.map((skill) => skill.text).join(", ")}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1, overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {job.description}
                    </Typography>
                  </CardContent>
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ m: 2 }}
                    onClick={() => handleDeleteConfirmation(job)}
                  >
                    حذف
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ m: 2 }}
                    onClick={() => handleEditJob(job)}
                  >
                    ویرایش
                  </Button>
                </Card>
              ))}
            </Box>
          )}
        </Stack>

        <PanelSidebar />
      </Stack>

      {/* Delete Confirmation Dialog */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>تایید حذف</DialogTitle>
        <DialogContent>
          <DialogContentText>
            آیا مطمئن هستید که می‌خواهید "{selectedJob?.title}" را حذف کنید؟
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            لغو
          </Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            حذف
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackSeverity}
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default JobUpdate;
